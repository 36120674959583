var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"height":"100%","background-image":"linear-gradient(to bottom, #dcdcdc, #bbedd9)"},attrs:{"fluid":_vm.$vuetify.breakpoint.mdAndUp ? true : false}},[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"memberinfo",attrs:{"width":"","flat":""}},[_c('v-card-title',[_c('span',{staticClass:"title-font-style red--text"},[_vm._v("REQUESTS")])]),_c('v-divider',{staticClass:"mt-n3"}),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Application No")]),_c('th',{staticClass:"text-left"},[_vm._v("Member No")]),_c('th',{staticClass:"text-left"},[_vm._v("Member ID")]),_c('th',{staticClass:"text-left"},[_vm._v("Member Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Loan Type")]),_c('th',{staticClass:"text-left"},[_vm._v("Amount")]),_c('th',{staticClass:"text-left"},[_vm._v("Approval Status")])])]),_c('tbody',[(_vm.guarantorRequests.length === 0)?[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"6"}},[_vm._v("No data available")])])]:_vm._e(),(_vm.guarantorRequests.length > 0)?_vm._l((_vm.guarantorRequests),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.application_no))]),_c('td',[_vm._v(_vm._s(item.member_no))]),_c('td',[_vm._v(_vm._s(item.id_no))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.loan_type))]),_c('td',[_vm._v(_vm._s(item.requested_amount))]),_c('td',[_c('v-chip',{staticClass:"ml-2",attrs:{"small":"","dark":"","color":_vm.getStatusColor(item.approval_status)}},[_vm._v(" "+_vm._s(item.approval_status)+" ")])],1),(item.approval_status === 'Pending')?_c('td',[_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","small":"","color":"success"},on:{"click":function($event){return _vm.openDialog(item)}}},[_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                          ? 'dep-name'
                          : 'mob-dep-name'},[_vm._v("Approve")])]),_c('v-btn',{staticClass:"ma-2",attrs:{"depressed":"","small":"","color":"error"},on:{"click":function($event){return _vm.rejectRequest(item)}}},[_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                          ? 'dep-name'
                          : 'mob-dep-name'},[_vm._v("Reject")])])],1):_vm._e()])}):_vm._e()],2)]},proxy:true}])})],1)],1)],1)],1),_c('v-dialog',{attrs:{"height":"600","persistent":"","width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-auto memberinfo",attrs:{"flat":""}},[_c('v-card-title',{},[_c('span',{staticClass:"text-overline red--text"},[_vm._v(" Add Amount ")])]),_c('v-card-text',[_c('v-form',{ref:"transferForm",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? 'text-overline new-main'
              : 'mob-main-font-style'},[_vm._v(" Enter Amount")]),_c('v-text-field',{staticClass:"small-label",attrs:{"label":"Amount","outlined":"","dense":"","rules":_vm.rules.required,"type":"number"},model:{value:(_vm.formData.Amount),callback:function ($$v) {_vm.$set(_vm.formData, "Amount", $$v)},expression:"formData.Amount"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"small":"","depressed":"","color":"error"},on:{"click":function($event){_vm.dialog = false}}},[_c('span',[_vm._v(" Close")])]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"small":"","depressed":"","color":"#25ad64"},on:{"click":function($event){return _vm.approveRequest()}}},[_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Approve")],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }