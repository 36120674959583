import constants from "./destinationConstants";
import call from "@/service/http";
import { EventBus } from "@/utils/eventBus";

export default {
  namespaced: true,
  state: {
    destinations: [],
    customerDetails: {},
    alert: {
      status: "",
      message: "",
    },
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
  },
  getters: {
    destinationGetters: (state) => (val) => state[val],
  },

  actions: {
    getDestinationAccounts: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", constants.destinations, data)
        .then((res) => {
          commit("MUTATE", { state: "destinations", value: res.data.f48 });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },

    sendMoney: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.destinations, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            EventBus.$emit("showToast", {
              type: "success",
              message: res.data.f100,
              icon: "success",
            });
            EventBus.$emit("successTransfer");
          } else {
            EventBus.$emit("showToast", {
              type: "error",
              message: res.data.f100,
              icon: "error",
            });
          }
         
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("showToast", {
            type: "error",
            message: "Error",
            icon: "error",
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    sendtoMobile: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.destinations, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            EventBus.$emit("showToast", {
              type: "success",
              message: res.data.f100,
              icon: "success",
            });
            EventBus.$emit("successSendMoney");
          } else {
            EventBus.$emit("showToast", {
              type: "error",
              message: res.data.f100,
              icon: "error",
            });
          }

          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("showToast", {
            type: "error",
            message: "Error",
            icon: "error",
          });
          commit("SET_LOADING", false, { root: true });
        });
    },
    depositMoney: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.destinations, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            EventBus.$emit("showToast", {
              type: "success",
              message: res.data.f100,
              icon: "success",
            });
            EventBus.$emit("successSendMoney");
          } else {
            EventBus.$emit("showToast", {
              type: "error",
              message: res.data.f100,
              icon: "error",
            });
          }
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("showToast", {
            type: "error",
            message: "Error",
            icon: "error",
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    getCustomerDetails: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", constants.destinations, data)
        .then((res) => {
          commit("MUTATE", { state: "customerDetails", value: res.data.f48 });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
  },
};
