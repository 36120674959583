import constants from "./profileConstants";
import call from "@/service/http";
import { EventBus } from "@/utils/eventBus";
// import AuthService from "@/modules/auth/authService";

export default {
  namespaced: true,
  state: {
    balances: {},
    statements: [],
    cost: {},
    freeBalances: {},
    Ministatement: []
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    profileGetters: (state) => (val) => state[val],
  },
  actions: {
    getBalances: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.balances, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            commit("MUTATE", { state: "balances", value: res.data.f48 });
            commit("SET_LOADING", false, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
    getFreeBalances: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.balances, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            commit("MUTATE", { state: "freeBalances", value: res.data.f48 });
            commit("SET_LOADING", false, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
    getStatement: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.statements, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            commit("MUTATE", { state: "statements", value: res.data.f48 });
          }
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
    getTrasactionCost: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.statements, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            commit("MUTATE", { state: "cost", value: res.data.f48 });
            commit("SET_LOADING", false, { root: true });
          }
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },

    changePin: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.statements, data)
        .then((res) => {
          EventBus.$emit("successChange", res.data.f100);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong. Try Again Later",
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    getMiniStatement: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.balances, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            commit("MUTATE", { state: "Ministatement", value: res.data.f48 });
            commit("SET_LOADING", false, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
  },
};
