import dashboard from "@/modules/dashboard/views/dashboard";
import transfer from "@/modules/transfer/components/transfer";
import mobiletransfer from "@/modules/transfer/components/mobiletransfer";
import standingOrders from "@/modules/transfer/components/standingOrders";
import Auth from "@/modules/auth/middleware/Auth";

export default [
  {
    path: "/money-transfer",
    component: dashboard,

    children: [
      {
        path: "/",
        name: "transfer",
        component: transfer,
        meta: { middleware: [Auth] },
      },

      {
        path: "/send-money",
        name: "mobiletransfer",
        component: mobiletransfer,
        meta: { middleware: [Auth] },
      },
      {
        path: "/standing-orders",
        name: "standingOrders",
        component: standingOrders,
        meta: { middleware: [Auth] },
      },
   
    ],
  },
];
