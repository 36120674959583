<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
  >
    <v-tabs
      color="deep-purple accent-4"
      centered
      icons-and-text
      v-model="TabIndex"
    >
      <!-- <v-tab @click="redirect('loanProducts')">Apply Loans</v-tab> -->
      <v-tab> Loans</v-tab>

      <v-tab-item v-for="item in items" :key="item">
        <v-card color="basil" flat>
          <v-card-text> <router-view /></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
export default {
  name: "loanDashboard",

  data: () => ({
    tab: null,
    items: ["Appetizers"],
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.$route.path.includes("view")) {
        v.$store.dispatch("loans/setCurrentTab", 1);
      } else {
        if (v.$route.path.includes("product")) {
          v.$store.dispatch("loans/setCurrentTab", 0);
        }
      }
    });
  },

  computed: {
    isProductRoute() {
      return this.$route.path.includes("product");
    },

    TabIndex: {
      get() {
        return this.$store.getters["loans/loansGetters"]("tabIndex");
      },
      set(newValue) {
        this.$store.dispatch("loans/setCurrentTab", newValue);
      },
    },
  },
  methods: {
    redirect(val) {
      this.$router.push({ name: val });
      if (val === "loans") {
        this.$store.dispatch("loans/setCurrentTab", 1);
      } else {
        this.$store.dispatch("loans/setCurrentTab", 0);
      }
    },
  },

  watch: {
    isProductRoute: {
      handler: function () {
        if (this.isProductRoute) {
          this.$store.dispatch("loans/setCurrentTab", 0);
        }
      },
    },
  },
};
</script>

<style scoped>
.image {
  width: 100%; /* Adjusted width */
  height: 100px;
  /* margin-top: 12px; */
}
.card-header-three {
  color: #000;
  background-color: #63c2de;
  font-size: 15px;
}

.dash-font-style {
  font-size: 13px;
  --font-family-sans-serif: ("DM Sans", sans-serif), "Helvetica Neue", Arial,
    "Noto Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

.mob-card-header-three {
  color: #000;
  background-color: #63c2de;
  font-size: 13px;
}
.mob-font-style {
  font-size: 10px;
  --font-family-sans-serif: ("DM Sans", sans-serif), "Helvetica Neue", Arial,
    "Noto Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
.cont-font-style {
  font-size: 15px;
  --font-family-sans-serif: ("DM Sans", sans-serif), "Helvetica Neue", Arial,
    "Noto Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
.status-font-style {
  font-size: 13px;
  --font-family-sans-serif: ("DM Sans", sans-serif), "Helvetica Neue", Arial,
    "Noto Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}


</style>
