<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
  >
    <v-row class="mt-4">
      <v-col cols="12" md="6" sm="10">
        <v-card class="" width="" flat>
          <v-card-title>
            <span class="title-font-style red--text">APPLICATION DETAILS</span>
          </v-card-title>
          <v-divider class="mt-n3" />

          <v-card-text>
            <v-form v-model="isValid" ref="scheduleForm" v-on:submit.prevent>
              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'text-caption new-main'
                    : 'mob-main-font-style'
                "
              >
                Choose Loan Product:</span
              >
              <v-autocomplete
                :items="loans ? loans : []"
                v-model="loanType"
                outlined
                dense
                :rules="rules.required"
                :item-value="(item) => item"
                :item-text="(item) => item"
                label="..select loan Product"
                class="small-label"
              ></v-autocomplete>

              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'text-caption new-main'
                    : 'mob-main-font-style'
                "
              >
                Installments:</span
              >
              <v-text-field
                label="Installments"
                v-model="formData.installment"
                outlined
                dense
                :rules="rules.required"
                type="number"
                class="small-label"
              ></v-text-field>

              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'text-caption new-main'
                    : 'mob-main-font-style'
                "
              >
                Amount:</span
              >
              <v-text-field
                label="Amount"
                v-model="formData.amount"
                outlined
                dense
                :rules="rules.required"
                type="number"
                class="small-label"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="mx-auto mt-n3">
            <v-btn
              small
              class="white--text ml-2 mt-n3"
              depressed
              color="#25ad64"
            >
              <span>Calculate</span>
            </v-btn>

            <v-spacer />
          </v-card-actions> </v-card
      ></v-col>
      <v-col>
        <v-card class="memberinfo" width="" flat>
          <v-card-title>
            <span class="title-font-style red--text">LOAN DETAILS</span>
          </v-card-title>
          <v-divider class="mt-n3" />

          <v-card-text class="mt-n3">
            <v-row class="mx-3"
              ><v-col>Loan Product:</v-col><v-col>{{loanType !== null ? loanType.product_description : 'N/A' }}</v-col>
              </v-row
            >
            <v-divider class="my-3" />
            <v-row class="mx-3"><v-col>Interest Rate:</v-col><v-col>{{loanType !== null ? `${loanType.interest}%` : 'N/A'}}</v-col></v-row>
            <v-divider class="my-3" />
            <v-row class="mx-3"
              ><v-col>Minimum Loan Amount:</v-col><v-col>{{loanType !== null ? loanType.min_loan_amt : 'N/A'}}</v-col
            ></v-row>
            <v-divider class="my-3" />
            <v-row class="mx-3"
              ><v-col>Maximum Installments:</v-col><v-col>N/A</v-col></v-row
            >
            <v-divider class="my-3" />
            <v-row class="mx-3"
              ><v-col>Maximum Loan Amount:</v-col><v-col>{{loanType !== null ? loanType.maximum_loan_amt  : 'N/A'}}</v-col></v-row
            >
            <v-divider class="my-3" />
            <v-row class="mx-3"
              ><v-col>Repayment Method:</v-col><v-col>{{loanType !== null ? loanType.repayment_method  : 'N/A'}}</v-col></v-row
            >
            <v-divider class="my-3" />
            <v-row class="mx-3"
              ><v-col>Repayment Frequency:</v-col><v-col>N/A</v-col></v-row
            >
            <v-divider class="my-3" />
            <v-row class="mx-3"
              ><v-col>Source:</v-col><v-col>N/A</v-col></v-row
            >
            <v-divider class="my-3" />
            <v-row class="mx-3"
              ><v-col>Recovery Mode:</v-col><v-col>N/A</v-col></v-row
            >
          </v-card-text>
        </v-card></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import payload from "@/mixin/payloadMethods";
import userInfo from "@/modules/auth/clientInfo";

export default {
  name: "LoanCalculator",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        mti: "0100",
        f2: userInfo.phone_number,
        f3: "600000",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f37: payload.methods.createRefno(),
        f68: "Get Loan Products Details",
        f123: "WEB",
        f90: userInfo.customer_id,
        f91: "BOSA",
        f92: userInfo.member_number,
      };

      v.$store.dispatch("loans/getLoanProducts", data);
    });
  },
  data() {
    return {
      isValid: false,
      formData: {
        loanNo: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
    };
  },

  computed: {},
  methods: {},
  watch: {},
  mounted() {},
};
</script>

<style scoped>
@import url("../style.css");
</style>
