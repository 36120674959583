<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
  >
    <v-card class="mx-auto mt-3" flat>
      <v-card-title>
        <!-- <v-btn color="primary" text :to="{ name: 'balances' }">
          <v-icon>mdi-arrow-left</v-icon>
          <span>Back</span>
        </v-btn> -->
      </v-card-title>
      <v-card-title>
        <span class="title-font-style">Loans Products</span>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="
          loanProducts.length > 0 || Array.isArray(loanProducts)
            ? loanProducts
            : []
        "
        :items-per-page="11"
        class="elevation-0 test-table"
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.interest`]="{ item }">
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
          >
            <span>{{ item.interest }}%</span></span
          >
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            outlined
            :to="{
              name: 'loanApplication',
              params: {
                code: encodeString(item.product_code),
              },
            }"
            small
            color="primary"
          >
            <span
              :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
              >Apply</span
            >
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <router-view />
  </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";

import payload from "@/mixin/payloadMethods";
export default {
  name: "loanProducts",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        mti: "0100",
        f2: userInfo.phone_number,
        f3: "600000",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f37: payload.methods.createRefno(),
        f68: "Get Loan Products Details",
        f123: "WEB",
        f90: userInfo.customer_id,
        f91: "BOSA",
        f92: userInfo.member_number,
      };

      v.$store.dispatch("loans/getLoanProducts", data);
    });
  },
  data: function () {
    return {
      headers: [
        {
          text: "Product Code",
          align: "start",
          sortable: false,
          value: "product_code",
        },
        { text: "Description", value: "product_description" },
        { text: "Installment Period", value: "installment_period" },
        { text: "Interest", value: "interest" },

        { text: "Maximum Loan Amount", value: "maximum_loan_amt" },
        { text: "Minimum Loan Amount", value: "min_loan_amt" },
        { text: "Repayment Method", value: "repayment_method" },
        { text: "Actions", value: "actions" },
      ],
    };
  },

  computed: {
    loanProducts() {
      return this.$store.getters["loans/loansGetters"]("loanProducts");
    },
  },
  methods: {
    encodeString(val) {
      const encodedData = btoa(val);
      return encodedData;
    },
  },
  watch: {},
  mounted() {},
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
