import dashboard from "@/modules/dashboard/views/dashboard";
import loans from "@/modules/loans/components/loans";
import loanStatements from "@/modules/loans/components/loanStatement";
import DetailedStatement from "@/modules/loans/components/DetailedStatement";
import LoansGuaranteed from "@/modules/loans/components/LoansGuaranteed";
import MemberIsLoanGuaranteed from "@/modules/loans/components/MemberIsLoanGuaranteed";
import memberAccountStatement from "@/modules/loans/components/memberAccountStatement";
import loanProducts from "@/modules/loans/components/loanProducts";
import loanDashboard from "@/modules/loans/components/loanDashboard";
import Auth from "@/modules/auth/middleware/Auth";
import loanApplication from "@/modules/loans/components/loanApplication";
import guarantorsReport from "@/modules/loans/components/guarantorsReport";
import guaranteedReport from "@/modules/loans/components/guaranteedReport";
import repaymentSchedule from "@/modules/loans/components/repaymentSchedule";
import loanCalculator from "@/modules/loans/components/loanCalculator";
import loansList from "@/modules/loans/components/loansList";
import guaranteeRequest from "@/modules/loans/components/guaranteeRequest";
import approveReject from "@/modules/loans/components/approvedReject";
import addGuarantor from "@/modules/loans/components/addGuarantor";
import uploadDocuments from "@/modules/loans/components/uploadDocuments";


export default [
  {
    path: "/loans",
    component: dashboard,
    children: [
      {
        path: "/",
        name: "loanDashboard",
        component: loanDashboard,
        meta: { middleware: [Auth] },
        children: [
          {
            path: "/product",
            name: "loanProducts",
            component: loanProducts,
            meta: { middleware: [Auth] },
            children: [],
          },
        
        ],
      },
      {
        path: "/running-loans",
        name: "loans",
        component: loans,
        meta: { middleware: [Auth] },
      },

      // {
      //   path: "/loan-statement/:code",
      //   name: "loanStatements",
      //   component: loanStatements,
      //   meta: { middleware: [Auth] },
      // },
      
      {
        path: "/loan-statement",
        name: "loanStatements",
        component: loanStatements,
        meta: { middleware: [Auth] },
      },
      {
        path: "/loan-statement",
        name: "loanStatements",
        component: loanStatements,
        meta: { middleware: [Auth] },
      },
      {
        path: "/detailed-statement",
        name: "DetailedStatement",
        component: DetailedStatement,
        meta: { middleware: [Auth] },
      },
      {
        path: "/loans-guaranteed",
        name: "LoansGuaranteed",
        component: LoansGuaranteed,
        meta: { middleware: [Auth] },
      },
      {
        path: "/memberisloanguaranteed",
        name: "MemberIsLoanGuaranteed",
        component: MemberIsLoanGuaranteed,
        meta: { middleware: [Auth] },
      },
      
      {
        path: "/guarantors-report",
        name: "guarantorsReport",
        component: guarantorsReport,
        meta: { middleware: [Auth] },
      },
      {
        path: "/guaranteed-report",
        name: "guaranteedReport",
        component: guaranteedReport,
        meta: { middleware: [Auth] },
      },
      {
        path: "/repayment-schedule",
        name: "repaymentSchedule",
        component: repaymentSchedule,
        meta: { middleware: [Auth] },
      },
      {
        path: "/member-statement",
        name: "memberAccountStatement",
        component: memberAccountStatement,
        meta: { middleware: [Auth] },
      },
      {
        path: "/loan-calculator",
        name: "loanCalculator",
        component: loanCalculator,
        meta: { middleware: [Auth] },
      },
      {
        path: "/loan-application",
        name: "loanApplication",
        component: loanApplication,
        meta: { middleware: [Auth] },
        children: [
          {
            path: "/add-guarantor/:code/:type",
            name: "addGuarantor",
            component: addGuarantor,
            meta: { middleware: [Auth] },
          },
        ],
      },
  
      {
        path: "/loan-list",
        name: "loansList",
        component: loansList,
        meta: { middleware: [Auth] },
      },
      {
        path: "/guarantee-request",
        name: "guaranteeRequest",
        component: guaranteeRequest,
        meta: { middleware: [Auth] },
      },
      {
        path: "/approved-rejected",
        name: "approveReject",
        component: approveReject,
        meta: { middleware: [Auth] },
      },

      {
        path: "/upload-doc/:code",
        name: "uploadDocuments",
        component: uploadDocuments,
        meta: { middleware: [Auth] },
      },
    ],
  },
];
