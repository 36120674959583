import AuthLayout from "./views/authLayout";

import Login from "@/modules/auth/components/login";
import NeedHelp from "@/modules/auth/components/NeedHelp";

import Register from "./components/Register";

import Landing from "@/modules/auth/components/landing";

export default [
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "landing",
        name: "Landing",
        component: Landing,
      },
      {
        path: "",
        name: "Login",
        component: Login,
      },
      {
        path: "help",
        name: "NeedHelp",
        component: NeedHelp,
      },

      {
        path: "register",
        name: "Register",
        component: Register,
      },
    ],
  },
];
