<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
  >
    <v-card v-if="!showBalances" class="mt-4">
      <v-skeleton-loader
        type="list-item-avatar-three-line, list-item-three-line, card-heading, list-item-three-line, list-item-three-line,list-item-three-line,list-item-three-line,list-item-three-line,list-item-three-line"
      ></v-skeleton-loader>
    </v-card>
    <v-card class="mx-auto mt-3" flat v-if="showBalances">
      <v-card-title>
        <v-btn color="primary" text :to="{ path: '/my-account' }">
          <v-icon>mdi-arrow-left</v-icon>
          <span>Back</span>
        </v-btn>
      </v-card-title>
      <v-card-title>
        <span class="title-font-style">Statement</span>
      </v-card-title>

      <!-- <v-card class="mt-8"> -->
      <v-data-table
        :headers="headers"
        :items="statements ? statements : []"
        :items-per-page="11"
        class="elevation-0"
        :mobile-breakpoint="0"
      >
        <!-- <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="title-font-style"
                >Dependants</v-toolbar-title
              >
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.dependant_id`]="{ item }">
            <span
              :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
              >{{ item.dependant_id }}</span
            >
          </template> -->
        <!-- <template v-slot:[`item.birthdate`]="{ item }">
            <span
              :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
              >{{ item.birthdate }}</span
            >
          </template>

         -->
      </v-data-table>
      <!-- </v-card> -->
    </v-card>
    <div class="text-center">
      <v-dialog persistent v-model="dialog" width="400" height="600">
        <v-card>
          <v-card-text class="justify-center service-title-text">
            Transcation Cost
          </v-card-text>
          <v-divider />

          <!-- Add transacionCost from Api -->
          <v-card-text class="justify-center service-text">
            You will be Charged KES {{ transacionCost.charges }} as transacion
            Cost
          </v-card-text>

          <v-card-actions>
            <v-btn
              small
              text
              class="white--text"
              color="red"
              :to="{ name: 'balances' }"
            >
              Back
            </v-btn>
            <v-spacer></v-spacer>

            <v-spacer />
            <v-btn small class="white--text" color="success" @click="Proceed()">
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";

import payload from "@/mixin/payloadMethods";
export default {
  name: "dependantInfo",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const transData = {
        mti: "0200",
        f2: userInfo.phone_number,
        f3: "101018",
        f4: 0,
        f5: "MINISTATEMENT",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f68: "ACCOUNT BALANCE TRANSACTION COST",
        f90: userInfo.customer_id,
        f123: "WEB",
        f92: userInfo.member_number,
        f93: userInfo.account_number,
      };
      v.$store.dispatch("Profile/getTrasactionCost", transData);
    });
  },
  data: function () {
    return {
      dialog: true,
      showBalances: false,
      headers: [
        {
          text: "Doc No",
          align: "start",
          sortable: false,
          value: "document_no",
        },

        { text: "Description", value: "description" },
        { text: "Type", value: "trans_type" },
        { text: "Amount", value: "amount" },
        { text: "Date", value: "transaction_date" },

        // { text: "View dependant", value: "actions" },
      ],
    };
  },

  computed: {
    statements() {
      return this.$store.getters["Profile/profileGetters"]("statements");
    },
    transacionCost() {
      return this.$store.getters["Profile/profileGetters"]("cost");
    },
  },
  methods: {
    Proceed() {
      this.showBalances = true;
      this.dialog = false;
      const data = {
        f93: this.$route.params.code,
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        mti: "0200",
        f11: payload.methods.getMinuteSecond(),
        f68: "Ministatement Enquiry",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno,
        f123: "WEB",
        f3: "380000",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };

      this.$store.dispatch("Profile/getStatement", data);
    },
  },
  watch: {},
  mounted() {},
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
