var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"height":"100%","background-image":"linear-gradient(to bottom, #dcdcdc, #bbedd9)"},attrs:{"fluid":_vm.$vuetify.breakpoint.mdAndUp ? true : false}},[_c('v-card',{staticClass:"mx-auto mt-3",attrs:{"flat":""}},[_c('v-card-title',[_c('v-btn',{attrs:{"color":"primary","text":"","to":{ name: 'mainDashboard' }}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_c('span',[_vm._v("Back")])],1)],1),_c('v-card-title',[_c('span',{staticClass:"title-font-style"},[_vm._v("C-ZAWADI")])]),_c('v-card-text',[_c('v-row',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"align-center",attrs:{"cols":"12","md":"5","sm":"12"}},[_c('v-img',{staticClass:"justify-center mt-n6",attrs:{"src":require("../../../assets/customer-loyalty.png"),"contain":""}})],1):_vm._e(),_c('v-col',{staticClass:"align-center",attrs:{"cols":"12","md":"7","sm":"12"}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":"","color":"#EDEADE","height":_vm.$vuetify.breakpoint.mdAndUp ? 120 : 100}},[_c('v-row',[_c('v-col',{attrs:{"align":"center","justify":"center","cols":"12","md":"12","sm":"12"}},[_c('v-row',[_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp
                              ? 'text-overline font-weight-bold red--text mt-2 ml-2'
                              : 'text-subtitle-2 green--text mt-0 ml-2'},[_c('span',{staticClass:"mt-0"},[_vm._v(" C-Zawadi Account")])])],1)],1),_c('v-col',{staticClass:"text-button mt-n4",attrs:{"cols":"12","md":"12","sm":"12","align":"center","justify":"center"}},[_vm._v(" "+_vm._s(_vm.loyalty ? _vm.loyalty.loyalty_account : "N/A"))])],1)],1),_c('v-card',{staticClass:"mt-8",attrs:{"flat":"","color":"#EDEADE","height":_vm.$vuetify.breakpoint.mdAndUp ? 120 : 100}},[_c('v-row',[_c('v-col',{attrs:{"align":"center","justify":"center","cols":"12","md":"12","sm":"12"}},[_c('v-row',[_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp
                              ? 'text-overline font-weight-bold red--text mt-2 ml-2'
                              : 'text-subtitle-2 green--text  mt-0 ml-2'},[_c('span',{staticClass:"mt-0"},[_vm._v(" Actual Balance")])])],1)],1),_c('v-col',{staticClass:"text-button mt-n4",attrs:{"cols":"12","md":"12","sm":"12","align":"center","justify":"center"}},[_vm._v(" "+_vm._s(_vm.loyalty ? _vm.loyalty.actual_balance : "N/A"))])],1)],1)],1),_c('v-col',[_c('v-card',{attrs:{"flat":"","color":"#EDEADE","height":_vm.$vuetify.breakpoint.mdAndUp ? 120 : 100}},[_c('v-row',[_c('v-col',{attrs:{"align":"center","justify":"center","cols":"12","md":"12","sm":"12"}},[_c('v-row',[_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp
                              ? 'text-overline font-weight-bold red--text mt-2 ml-2'
                              : 'text-subtitle-2 green--text mt-0 ml-2'},[_c('span',{staticClass:"mt-0"},[_vm._v(" Account Type")])])],1)],1),_c('v-col',{staticClass:"text-button mt-n4",attrs:{"cols":"12","md":"12","sm":"12","align":"center","justify":"center"}},[_vm._v(" "+_vm._s(_vm.loyalty ? _vm.loyalty.account_type : "N/A"))])],1)],1),_c('v-card',{staticClass:"mt-8",attrs:{"flat":"","color":"#EDEADE","height":_vm.$vuetify.breakpoint.mdAndUp ? 120 : 100}},[_c('v-row',[_c('v-col',{attrs:{"align":"center","justify":"center","cols":"12","md":"12","sm":"12"}},[_c('v-row',[_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp
                              ? 'text-overline font-weight-bold red--text mt-2 ml-2'
                              : 'text-subtitle-2 green--text mt-0 ml-2'},[_c('span',{staticClass:"mt-0"},[_vm._v(" Available Balance")])])],1)],1),_c('v-col',{staticClass:"text-button mt-n4",attrs:{"cols":"12","md":"12","sm":"12","align":"center","justify":"center"}},[_vm._v(" "+_vm._s(_vm.loyalty ? _vm.loyalty.available_balance : "N/A"))])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }