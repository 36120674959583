<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
  >
    <v-card class="mx-auto mt-3 justify-center" flat>
      <v-card-title>
        <v-btn color="primary" text :to="{ name: 'loans' }">
          <v-icon>mdi-arrow-left</v-icon>
          <span>Back</span>
        </v-btn>
      </v-card-title>
      <v-card-title>
        <span class="title-font-style">Detailed Statement </span>
        <v-spacer />
        <v-btn
            small
            @click="dialog = true"
            class="white--text ml-2 mt-n3"
            depressed
            color="#25ad64"
          >
            <span>Show Filter for Dates</span>
          </v-btn>
      </v-card-title>
      <iframe style="width: 98%; height: 700px" :src="base64PDF ? base64PDF : ''" class="iframe ml-4" v-if="base64PDF"></iframe>
    </v-card>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      width="500"
      persistent
      overlay-opacity="0.6"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Please Filter based on Date
        </v-card-title>

        <v-card-text class="mt-3">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formData.from"
                label="From"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                @input="formatDate(formData.from)"
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              scrollable
              v-model="formData.from"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formData.to"
                label="to"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                @input="formatDate(formData.to)"
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              scrollable
              v-model="formData.to"
              @change="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            small
            @click="dialog = false"
            class="white--text ml-2 "
            depressed
            color="red"
          >
            <span>Close</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="checkStatement()"
            class="white--text ml-2"
            depressed
            color="#25ad64"
          >
            <span>Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";
import payload from "@/mixin/payloadMethods";
export default {
  name: "DetailedStatement",

  beforeRouteEnter(to, from, next) {
    next(() => {});
  },
  data: function () {
    return {
      base64PDF: "",
      menu: false,
      menu2: false,
      dialog: true,
      formData: {
        from: "",
        to: "",
      },
    };
  },

  computed: {
    statement() {
      return this.$store.getters["loans/loansGetters"]("statement");
    },
    pdfFile() {
      return this.statement.base_report;
    },
  },
  methods: {
    formatDate(date) {
      if (date) {
        const [year, month, day] = date.split("-");
        this.formData.from = `${day}-${month}-${year}`;
      }
    },
    checkStatement() {
      const data = {
        mti: "0200",
        f2: userInfo.phone_number,
        f3: "500020",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f37: payload.methods.createRefno(),
        f60: this.formData.from,
        f61: this.formData.to,
        f68: "Get Loan Detailed Statement",
        f90: userInfo.customer_id,
        f123: "WEB",
        f92: userInfo.member_number,
      };

      this.$store.dispatch("loans/getLoanStatement", data);
      this.dialog = false;
    },
  },
  watch: {
    pdfFile: {
      handler: function () {
        if (this.pdfFile) {
          this.base64PDF = `data:application/pdf;base64,${this.pdfFile}`;
        }
      },
    },
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
