var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"height":"100%","background-image":"linear-gradient(to bottom, #dcdcdc, #bbedd9)"},attrs:{"fluid":_vm.$vuetify.breakpoint.mdAndUp ? true : false}},[_c('v-card',{staticClass:"mx-auto mt-3",attrs:{"flat":""}},[_c('v-card-title'),_c('v-card-title',[_c('span',{staticClass:"title-font-style"},[_vm._v("Loans Products")])]),_c('v-data-table',{staticClass:"elevation-0 test-table",attrs:{"headers":_vm.headers,"items":_vm.loanProducts.length > 0 || Array.isArray(_vm.loanProducts)
          ? _vm.loanProducts
          : [],"items-per-page":11,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:`item.interest`,fn:function({ item }){return [_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'},[_c('span',[_vm._v(_vm._s(item.interest)+"%")])])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"outlined":"","to":{
            name: 'loanApplication',
            params: {
              code: _vm.encodeString(item.product_code),
            },
          },"small":"","color":"primary"}},[_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'},[_vm._v("Apply")])])]}}],null,true)})],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }