<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
  >
    <!-- <v-row class="mt-4"> -->
    <v-card class="mx-4 mt-4" flat>
      <v-row class="justify-center">
        <v-col cols="12" sm="6" md="4">
          <span
            :class="
              !$vuetify.breakpoint.mdAndUp
                ? 'text-caption mx-2'
                : 'text-overline mx-2'
            "
            >Start Date</span
          >
          <v-menu
            v-model="start"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                class="mx-2"
                v-model="formData.startDate"
                label="Start Date"
                prepend-inner-icon="mdi-calendar"
                dense
                v-bind="attrs"
                v-on="on"
                
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.startDate"
              @input="start = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          xs="6"
          :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n10' : ''"
         
        >
          <span
            :class="
              !$vuetify.breakpoint.mdAndUp
                ? 'text-caption mx-2'
                : 'text-overline mx-2'
            "
            >End Date</span
          >
          <v-menu
            v-model="end"
            :close-on-content-click="false"
            :nudge-right="0"
            transition="scale-transition"
            
            max-width="290"
            
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                class="mx-2"
                
                v-model="formData.endDate"
                label="End Date"
                prepend-inner-icon="mdi-calendar"
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.endDate"
              @input="end = false"
              class=""
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="2"
          align="center"
          class="mt-5"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-btn class="mt-5 white--text" depressed small color="teal">
            view guaranteed loan report
          </v-btn></v-col
        >
        <v-col
          cols="12"
          sm="12"
          md="2"
          class="mt-n6 "
          align="center"
          v-if="!$vuetify.breakpoint.mdAndUp"
        >
        <v-spacer/>
          <v-btn class=" white--text" depressed x-small color="teal">
            view guaranteed loan report
          </v-btn>
          <v-spacer/></v-col
        ></v-row
      >
    </v-card>
    <!-- </v-row> -->
  </v-container>
</template>

<script>
// import payload from "@/mixin/payloadMethods";
// import userInfo from "@/modules/auth/clientInfo";

export default {
  name: "GuarantorsReport",

  beforeRouteEnter(to, from, next) {
    next(() => {

    });
  },
  data() {
    return {
      end: false,
      start: false,
      formData: {
        startDate: "",
        endDate: "",
      },
    };
  },

  computed: {

  },
  methods: {
  
  },
  watch: {},
  mounted() {},
};
</script>

<style scoped>
@import url("../style.css");
</style>
