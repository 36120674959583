<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
  >
    <!-- <v-row class="mt-4"> -->
    <v-card class="mx-auto" width="" flat>
      <v-card-title>
        <span class="title-font-style red--text">Loan Repayment Schedule</span>
      </v-card-title>
      <v-divider class="mt-n3" />

      <v-card-text>
        <v-form v-model="isValid" ref="scheduleForm" v-on:submit.prevent>
          <span
            :class="
              $vuetify.breakpoint.mdAndUp
                ? 'text-caption new-main'
                : 'mob-main-font-style'
            "
          >
            Select Loan No:</span
          >
          <v-autocomplete
            :items="loans.length > 0 || Array.isArray(loans) ? loans : []"
            v-model="formData.loanNo"
            outlined
            dense
            :rules="rules.required"
            :item-value="(item) => item.loan_no"
            :item-text="(item) => item.loan_name"
            label="..select loan No"
            class="small-label"
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-card-actions class="mx-auto mt-n3">
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          small
          @click="Proceed()"
          class="white--text ml-2 mt-n3"
          depressed
          color="#25ad64"
        >
          <span>View Repayment</span>
        </v-btn>
        <v-btn
          v-if="!$vuetify.breakpoint.mdAndUp"
          small
          @click="Proceed()"
          depressed
          color="#25ad64"
        >
          <span>View Repayment</span>
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <v-card class="mx-auto mt-3" flat>
      <v-card-title>
        <span class="title-font-style">Repayment Schedule </span>
      </v-card-title>
      <iframe style="width: 98%; height: 700px" :src="base64PDF ? base64PDF : ''" class="iframe ml-4" v-if="base64PDF"></iframe>
    </v-card>
    <!-- </v-row> -->
  </v-container>
</template>

<script>
import payload from "@/mixin/payloadMethods";
import userInfo from "@/modules/auth/clientInfo";

export default {
  name: "RepaymentSchedule",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        mti: "0100",
        f11: payload.methods.getMinuteSecond(),
        f68: "Loan Balance Enquiry",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: "611000",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };

      v.$store.dispatch("loans/getLoans", data);
  
    });
  },
  data() {
    return {
      isValid: false,
      base64PDF: "",
      formData: {
        loanNo: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
    };
  },

  computed: {
    statement() {
      return this.$store.getters["loans/loansGetters"]("statement");
    },
    pdfFile() {
      return this.statement.base_report;
    },
    loans() {
      return this.$store.getters["loans/loansGetters"]("loans");
    },
  },
  methods: {
    Proceed() {
      const data = {
        mti: "0200",
        f2: userInfo.phone_number,
        f3: "500000",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f37: payload.methods.createRefno(),
        f68: "repaymentSchedule",
        f90: userInfo.customer_id,
        f91:this.formData.loanNo,
        f123: "WEB",
        f92: userInfo.member_number,
      };

      this.$store.dispatch("loans/getLoanStatement", data);
    },
  },
  watch: {
    pdfFile: {
      handler: function () {
        if (this.pdfFile) {
          this.base64PDF = `data:application/pdf;base64,${this.pdfFile}`;
        }
      },
    },
  },
};
</script>

<style scoped>
@import url("../style.css");
</style>
