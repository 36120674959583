import { render, staticRenderFns } from "./mobiletransfer.vue?vue&type=template&id=40abcaff&scoped=true&"
import script from "./mobiletransfer.vue?vue&type=script&lang=js&"
export * from "./mobiletransfer.vue?vue&type=script&lang=js&"
import style0 from "./mobiletransfer.vue?vue&type=style&index=0&id=40abcaff&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40abcaff",
  null
  
)

export default component.exports