import dashboard from "@/modules/dashboard/views/dashboard";
import sourceDashboard from "@/modules/source/components/sourceDashboard";
import Auth from "@/modules/auth/middleware/Auth";

export default [
  {
    path: "/source",
    component: dashboard,

    children: [
      {
        path: "/",
        name: "sourceDashboard",
        component: sourceDashboard,
        meta: { middleware: [Auth]},
      },
    ],
  },
];

