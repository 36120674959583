// import statconst from "./constants";
import CryptoJS from "crypto-js";

export default {
  methods: {
    //f7
    getTransactionDate() {
      const currentDate = new Date();
      const dateFormat = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      const [
        { value: month },
        ,
        { value: day },
        ,
        { value: year },
        ,
        { value: hour },
        ,
        { value: minute },
        ,
        { value: second },
      ] = dateFormat.formatToParts(currentDate);

      return `${year}${month}${day}${hour}${minute}${second}`;
    },
    //f11
    getMinuteSecond() {
      const currentDate = new Date();
      const dateFormat = new Intl.DateTimeFormat("en-US", {
        minute: "2-digit",
        second: "2-digit",
      });

      const [{ value: minute }, , { value: second }] =
        dateFormat.formatToParts(currentDate);

      return `${minute}${second}`;
    },
    //f12
    getHourMinuteSecond() {
      const currentDate = new Date();
      const dateFormat = new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      const [{ value: hour }, , { value: minute }, , { value: second }] =
        dateFormat.formatToParts(currentDate);

      return `${hour}${minute}${second}`;
    },
    //  f13
    getHourMinute() {
      const currentDate = new Date();
      const dateFormat = new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });

      const [{ value: hour }, , { value: minute }] =
        dateFormat.formatToParts(currentDate);

      return `${hour}${minute}`;
    },

    createRefno() {
      let filename = "0.0.0.0.0.5";
      let numVal = "";
      var numberIndex = 0;

      let letters = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let maxletterIndex = 25;
      let minLetterIndex = 0;
      //random numbers at the end of the refno 4 digits
      let letterMin = 1;
      let letterMax = 26;
      let numMin = 0;
      let numMax = 9;
      let digit7Val = Math.floor(Math.random() * (numMax - numMin) + numMin);
      let digit8Val = Math.floor(
        Math.random() * (letterMax - letterMin) + letterMin
      );
      let digit9Val = Math.floor(
        Math.random() * (letterMax - letterMin) + letterMin
      );
      let digit10Val = Math.floor(
        Math.random() * (letterMax - letterMin) + letterMin
      );
      let letter10Val = "";
      let letter9Val = "";
      let letter8Val = "";
      let letter7Val = "";
      let letter6Val = "";
      let letter5Val = "";
      let letter4Val = "";
      let letter3Val = "";

      if (digit7Val > numMax) {
        digit7Val = numMin;
      }
      //check if the digit is greater than maximum digit and then change it to minimum
      if (digit8Val > maxletterIndex) {
        digit8Val = minLetterIndex;
      }
      if (digit9Val > maxletterIndex) {
        digit9Val = minLetterIndex;
      }
      if (digit10Val > maxletterIndex) {
        digit10Val = minLetterIndex;
      }
      let count = filename; //get the file contents here
      if (typeof count !== "string" || count.length === 0 || count.length < 6) {
        count =
          "0.0.0." +
          Math.floor(Math.random() * (numMax - numMin) + numMin) +
          "." +
          Math.floor(Math.random()(numMax - numMin) + numMin) +
          "." +
          Math.floor(Math.random()(numMax - numMin) + numMin);
      }
      let positions = count.split(".");
      let refno = "";
      //all the sequencial numbers are assigned values in their respective positions from the file
      let digit6Val = parseInt(positions[5]); //the sixth digit;
      let digit5Val = parseInt(positions[4]);
      let digit4Val = parseInt(positions[3]);
      let digit3Val = parseInt(positions[2]);
      let digit2Val = parseInt(positions[1]);
      let digit1Val = parseInt(positions[0]);
      //letters
      let letter2Val = "";
      let letter1Val = "";

      //check if the digit is greater than maximum digit and then change it to minimum
      if (digit1Val > maxletterIndex) {
        digit1Val = minLetterIndex;
      }
      if (digit2Val > maxletterIndex) {
        digit2Val = minLetterIndex;
        digit1Val = digit1Val + 1; //increase the value of the digit on the left by 1
      }
      if (digit3Val > maxletterIndex) {
        if (digit3Val < maxletterIndex + 10) {
          numVal = digit3Val - maxletterIndex;

          let numbers_size = numbers.length;
          for (numberIndex = 0; numberIndex < numbers_size; numberIndex++) {
            let numberX = numbers[numberIndex];
            //pos 4
            if (numVal === numberX) {
              letter3Val = numberX;
            }
            if (letter3Val.length != 0) {
              break;
            }
          }
        } else {
          digit3Val = minLetterIndex;
          digit2Val = digit2Val + 1; //increase the value of the digit on the left by 1
        }
      }

      if (digit4Val > numMax) {
        digit4Val = minLetterIndex;
        digit3Val = digit3Val + 1; //increase the value of the digit on the left by 1
      } //number
      if (digit5Val > maxletterIndex) {
        digit5Val = minLetterIndex;
        digit4Val = digit4Val + 1;
      }
      if (digit6Val > maxletterIndex) {
        digit6Val = minLetterIndex;
        digit5Val = digit5Val + 1;
      }

      let letters_size = letters.length;
      for (var letterIndex = 0; letterIndex < letters_size; letterIndex++) {
        let letterX = letters[letterIndex];
        //pos 10
        if (digit10Val === letterIndex) {
          letter10Val = letterX;
        }
        //pos 9
        if (digit9Val === letterIndex) {
          letter9Val = letterX;
        }
        //pos 8
        if (digit8Val === letterIndex) {
          letter8Val = letterX;
        }
        //pos 6
        if (digit6Val === letterIndex) {
          letter6Val = letterX;
        }
        //pos 5
        if (digit5Val === letterIndex) {
          letter5Val = letterX;
        }
        //pos 3
        if (digit3Val === letterIndex) {
          letter3Val = letterX;
        }
        //pos 2
        if (digit2Val === letterIndex) {
          letter2Val = letterX;
        }
        //pos 1
        if (digit1Val === letterIndex) {
          letter1Val = letterX;
        }

        if (
          letter1Val.length != 0 &&
          letter2Val.length != 0 &&
          letter3Val.length != 0 &&
          letter5Val.length != 0 &&
          letter6Val.length != 0 &&
          letter8Val.length != 0 &&
          letter9Val.length != 0 &&
          letter10Val.length != 0
        ) {
          break; //stop only if all the letters have been filled up with values
        }
      }
      digit6Val = digit6Val + 1; //increament the last index in the sequence
      let numbers_size = numbers.length;
      for (numberIndex = 0; numberIndex < numbers_size; numberIndex++) {
        let numberX = numbers[numberIndex];
        //pos 4
        if (digit4Val === numberX) {
          letter4Val = numberX;
        }
        if (digit7Val === numberX) {
          letter7Val = numberX;
        }

        if (letter4Val.length != 0 && letter7Val.length != 0) {
          break; //stop only if all the letters have been filled up with values
        }
      }
      //return all the digits to theier arrays in their indices
      positions[0] = digit1Val;
      positions[1] = digit2Val;
      positions[2] = digit3Val;
      positions[3] = digit4Val;
      positions[4] = digit5Val;
      positions[5] = digit6Val;

      refno =
        letter1Val +
        letter2Val +
        letter3Val +
        letter4Val +
        letter5Val +
        letter6Val +
        letter7Val +
        letter8Val +
        letter9Val +
        letter10Val;
      let _count = positions.join("."); //combine them here
      this.refno = _count;
      return refno;
    },
    generatePasswordHash(pin, phonenumber) {
      // const crypto = require("crypto-browserify"); // Use crypto-browserify
      const enc = pin + phonenumber;

      const encryptedPin = CryptoJS.HmacSHA256(
        CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(enc)),
        "jbog"
      ).toString(CryptoJS.enc.Hex);

      return encryptedPin;
    },

  },
};
