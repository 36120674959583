var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"height":"100%","background-image":"linear-gradient(to bottom, #dcdcdc, #67e7b3)"},attrs:{"fluid":_vm.$vuetify.breakpoint.mdAndUp ? true : false}},[_c('v-alert',{staticClass:"mt-2",attrs:{"text":"","color":"teal","icon":"mdi-clock-fast","border":"left"}},[_c('span',{staticClass:"dash-font-style"},[_vm._v("Welcome, "+_vm._s(_vm.fullNames ? _vm.fullNames : "")+" ! ")])]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 12 : 6,"md":"3","sm":"12"}},[_c('v-card',{attrs:{"flat":"","color":"green","height":_vm.$vuetify.breakpoint.mdAndUp ? 120 : 100}},[_c('v-row',[_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp
                ? 'mt-0 ml-2 white--text'
                : 'text-caption font-weight-bold mt-0 ml-2 white--text'},[_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'balance-text mt-0 mb-3'
                  : 'text-caption'},[_vm._v(" "+_vm._s(_vm.BOSA ? _vm.BOSA[0].balances : "N/A"))]),_c('p'),_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'mt-3 text-overline font-weight-bold'
                  : ' text-caption mt-n8'},[_vm._v(" "+_vm._s(_vm.BOSA ? _vm.BOSA[0].account_name : ""))])])],1)],1)],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 12 : 6,"md":"3","sm":"12"}},[_c('v-card',{attrs:{"flat":"","color":"#17a2d0","height":_vm.$vuetify.breakpoint.mdAndUp ? 120 : 100}},[_c('v-row',[_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp
                ? 'mt-0 ml-2 white--text'
                : 'text-caption font-weight-bold mt-0 ml-2 white--text'},[_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'balance-text mt-0 mb-3'
                  : 'text-caption'},[_vm._v(" "+_vm._s(_vm.BOSA ? _vm.BOSA[1].balances : "N/A"))]),_c('p'),_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'mt-3 text-overline font-weight-bold'
                  : ' text-caption mt-n8'},[_vm._v(" "+_vm._s(_vm.BOSA ? _vm.BOSA[1].account_name : ""))])])],1)],1)],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 12 : 6,"md":"3","sm":"12"}},[_c('v-card',{attrs:{"flat":"","color":"deep-purple","height":_vm.$vuetify.breakpoint.mdAndUp ? 120 : 100}},[_c('v-row',[_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp
                ? 'mt-0 ml-2 white--text'
                : 'text-caption font-weight-bold mt-0 ml-2 white--text '},[_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'balance-text mt-0 mb-3'
                  : 'text-caption'},[_vm._v(" "+_vm._s(_vm.BOSA ? _vm.BOSA[2].balances : "N/A"))]),_c('p'),_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'mt-3 text-overline font-weight-bold'
                  : ' text-caption mt-n8'},[_vm._v(" "+_vm._s(_vm.BOSA ? _vm.BOSA[2].account_name : ""))])])],1)],1)],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 12 : 6,"md":"3","sm":"12"}},[_c('v-card',{attrs:{"flat":"","color":"#BA9913","height":_vm.$vuetify.breakpoint.mdAndUp ? 120 : 100}},[_c('v-row',[_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp
                ? 'mt-0 ml-2 white--text'
                : 'text-caption font-weight-bold mt-0 ml-2 white--text'},[_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'balance-text mt-0 mb-3'
                  : 'text-caption'},[_vm._v(" "+_vm._s(_vm.FOSA ? _vm.FOSA.balance : ""))]),_c('p'),_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'mt-3 text-overline font-weight-bold'
                  : ' text-caption mt-n8'},[_vm._v(" ORDINARY ACCOUNT")])])],1)],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 12 : 6,"md":"3","sm":"12"}},[_c('v-card',{attrs:{"flat":"","color":"#EDEADE","height":_vm.$vuetify.breakpoint.mdAndUp ? 120 : 100},on:{"click":function($event){return _vm.redirect('balances')}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12","justify":"center"}},[_c('v-row',[_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp
                    ? 'text-overline font-weight-bold mt-2 ml-2'
                    : 'text-caption font-weight-bold mt-0 ml-2'},[_c('span',{staticClass:"mt-6 card-text"},[_vm._v(" My account")])])],1)],1),_c('v-col',{class:!_vm.$vuetify.breakpoint.mdAndUp ? 'align-end mt-n6 ' : 'align-end mt-6',attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-img',{staticClass:"justify-center mt-n6",attrs:{"src":require("../../../assets/prof.png"),"contain":"","height":!_vm.$vuetify.breakpoint.mdAndUp ? '60' : '90'}})],1)],1)],1)],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 12 : 6,"md":"3","sm":"12"}},[_c('v-card',{attrs:{"flat":"","color":"#EDEADE","height":_vm.$vuetify.breakpoint.mdAndUp ? 120 : 100},on:{"click":function($event){return _vm.redirect('loans')}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12","justify":"center"}},[_c('v-row',[_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp
                    ? 'text-overline font-weight-bold mt-6 ml-2'
                    : 'text-caption font-weight-bold mt-0 ml-2'},[_c('span',{staticClass:"mt-6"},[_vm._v(" Loans")])])],1)],1),_c('v-col',{class:!_vm.$vuetify.breakpoint.mdAndUp ? 'align-end mt-n6 ' : 'align-end mt-6',attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-img',{staticClass:"justify-center mt-n6",attrs:{"src":require("../../../assets/loan.png"),"contain":"","height":!_vm.$vuetify.breakpoint.mdAndUp ? '60' : '90'}})],1)],1)],1)],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 12 : 6,"md":"3","sm":"12"}},[_c('v-card',{attrs:{"flat":"","color":"#EDEADE","height":_vm.$vuetify.breakpoint.mdAndUp ? 120 : 100},on:{"click":function($event){return _vm.redirect('loans')}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12","justify":"center"}},[_c('v-row',[_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp
                    ? 'text-overline font-weight-bold mt-6 ml-2'
                    : 'text-caption font-weight-bold mt-0 ml-2'},[_c('span',{staticClass:"mt-6"},[_vm._v(" statement")])])],1)],1),_c('v-col',{class:!_vm.$vuetify.breakpoint.mdAndUp ? 'align-end mt-n6 ' : 'align-end mt-6',attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-img',{staticClass:"justify-center mt-n6",attrs:{"height":!_vm.$vuetify.breakpoint.mdAndUp ? '60' : '90',"src":require("../../../assets/stat.png"),"contain":""}})],1)],1)],1)],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 12 : 6,"md":"3","sm":"12"}},[_c('v-card',{attrs:{"flat":"","color":"#E0E8D7","height":_vm.$vuetify.breakpoint.mdAndUp ? 120 : 100},on:{"click":function($event){return _vm.redirect('loyalty')}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12","justify":"center"}},[_c('v-row',[_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp
                    ? 'text-overline font-weight-bold mt-6 ml-2'
                    : 'text-caption font-weight-bold mt-0 ml-2'},[_c('span',{staticClass:"mt-6"},[_vm._v(" c-zawadi")])])],1)],1),_c('v-col',{class:!_vm.$vuetify.breakpoint.mdAndUp ? 'align-end mt-n6 ' : 'align-end mt-6',attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-img',{class:!_vm.$vuetify.breakpoint.mdAndUp
                  ? 'justify-center mt-n6 '
                  : 'justify-center mt-0',attrs:{"height":!_vm.$vuetify.breakpoint.mdAndUp ? '40' : '70',"src":require("../../../assets/loyalty.png"),"contain":""}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"memberinfo",attrs:{"flat":""}},[_c('v-alert',{staticClass:"white--text",attrs:{"align":"center","color":"#537213"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account")]),_vm._v(" Member Information ")],1),_c('v-row',{staticClass:"mx-3"},[_c('v-col',[_vm._v("Member Number:")]),_c('v-col',[_vm._v(_vm._s(_vm.clientInfo.member_number ? _vm.clientInfo.member_number : "N/A"))])],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:"mx-3"},[_c('v-col',[_vm._v("Name:")]),_c('v-col',[_vm._v(_vm._s(_vm.fullNames ? _vm.fullNames : ""))])],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:"mx-3"},[_c('v-col',[_vm._v("Account Category:")]),_c('v-col')],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:"mx-3"},[_c('v-col',[_vm._v("Email Address:")]),_c('v-col',[_vm._v(_vm._s(_vm.clientInfo.email_address ? _vm.clientInfo.email_address : "N/A"))])],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:"mx-3"},[_c('v-col',[_vm._v("Phone Number:")]),_c('v-col',[_vm._v("+"+_vm._s(_vm.clientInfo.phone_number ? _vm.clientInfo.phone_number : "N/A"))])],1)],1)],1),_c('v-col',[_c('v-card',{staticClass:"memberinfo",attrs:{"flat":""}},[_c('v-alert',{staticClass:"white--text",attrs:{"align":"center","color":"#537213"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-format-list-bulleted")]),_vm._v(" Mini Statement ")],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Date")]),_c('th',{staticClass:"text-left"},[_vm._v("Description")]),_c('th',{staticClass:"text-left"},[_vm._v("Amount(ksh)")])])]),_c('tbody',_vm._l((_vm.Ministatement
                  ? _vm.Ministatement.slice(0, 4)
                  : []),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.transaction_date))]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',[_vm._v(_vm._s(item.amount))])])}),0)]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }