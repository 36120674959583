<template>
  <v-container class="body-text" :fluid="$vuetify.breakpoint.mdAndUp ? true : false" style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    ">
    <v-card class="mx-auto mt-3" flat>


      <v-card class="memberinfo" flat>
        <v-alert class="white--text" align="center" color="#537213">
          <v-icon color="white">mdi-format-list-bulleted</v-icon> Mini
          Statement
        </v-alert>

        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Date</th>
                <th class="text-left">Description</th>
                <th class="text-left">Amount(ksh)</th>
              </tr>
            </thead>
            <tbody class="amount-text">
              <tr v-for="(item, i) in Ministatement
                ? Ministatement
                : []" :key="i">
                <td class="amount-text">{{ item.transaction_date }}</td>
                <td class="amount-text">{{ item.description }}</td>
                <td class="amount-text">{{ item.amount }}</td>
                <td class="amount-text">
                  <v-chip  :color="item.trans_type === 'CR' ? 'green': 'red'" small outlined>{{ item.trans_type === 'CR' ? 'Credit' : 'Debit' }}</v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-card>

  </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";
// import { EventBus } from "@/utils/eventBus";
import payload from "@/mixin/payloadMethods";
export default {
  name: "LoansList",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const miniData = {
        mti: "0200",
        f2: userInfo.phone_number,
        f3: "380000",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f68: "Mini Statement",
        f90: userInfo.customer_id,
        f123: "WEB",
        f92: userInfo.member_number,
        f93: userInfo.account_number,
      };

      v.$store.dispatch("Profile/getMiniStatement", miniData);
    });
  },
  data: function () {
    return {
      headers: [
        {
          text: "Application Date",
          align: "start",
          sortable: false,
          value: "application_date",
        },
        {
          text: "Loan No",
          align: "start",
          sortable: false,
          value: "loan_no",
        },
        { text: "Loan Type", value: "loan_name" },
        { text: "Loan Amount", value: "loan_amount" },
        // { text: "Loan Name", value: "loan_name" },
        { text: "Loan Submitted", value: "" },
        // { text: "Loan Installment", value: "loan_installment" },
        { text: "Undergoing Appraisal", value: "appraisal" },
        { text: "Action", value: "actions" },
      ],
      test: "",
      checkbox: false,
      isValid: false,
      dialog: false,
      errorDialog: false,
      sucessDialog: false,
      formData: {
        phone: "",
        amount: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
      paymentOption: "FOSA",
      loanSelected: {},
      runningLoans: [],
    };
  },

  computed: {
    Ministatement() {
      return this.$store.getters["Profile/profileGetters"]("Ministatement");
    },
    clientInfo() {
      return userInfo;
    },
  },
  methods: {

  },

  mounted() {

  },

  watch: {

  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
