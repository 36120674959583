import constants from "./loansConstants";
import call from "@/service/http";
import { EventBus } from "@/utils/eventBus";

export default {
  namespaced: true,
  state: {
    loans: [],
    statement: {},
    loanProducts: [],
    tabIndex: 0,
    loanReport: null,
    guarantorsList: [],
    singleGuarantor: {},
    guarantorRequests: [],
    mobileLoanProducts: [],
    sendToPerson: {},
    alert: {
      status: "",
      message: "",
    },
  },
  mutations: {
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    loansGetters: (state) => (val) => state[val],
  },

  actions: {
    getLoans: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          commit("MUTATE", { state: "loans", value: res.data.f48 });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
    getLoanStatement: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            EventBus.$emit("showToast", {
              type: "success",
              message: res.data.f100,
              icon: "success",
            });
            commit("MUTATE", { state: "statement", value: res.data.f48 });
          } else {
            EventBus.$emit("showToast", {
              type: "error",
              message: res.data.f100,
              icon: "error",
            });
          }

          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
    getLoanProducts: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          commit("MUTATE", { state: "loanProducts", value: res.data.f48 });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },

    getMobileLoanProducts: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          commit("MUTATE", {
            state: "mobileLoanProducts",
            value: res.data.f48,
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },

    checkEligibility: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            EventBus.$emit("showToast", {
              type: "success",
              message: res.data.f100,
              icon: "success",
            });
            EventBus.$emit("successSendMoney");
          } else {
            EventBus.$emit("showToast", {
              type: "error",
              message: res.data.f100,
              icon: "error",
            });
          }
          EventBus.$emit("eligibility", res.data.f39);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          EventBus.$emit("stoploanDialog", err);
          ("");
          commit("SET_LOADING", false, { root: true });
        });
    },
    uploadDoc: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            EventBus.$emit("showToast", {
              type: "success",
              message: res.data.f100,
              icon: "success",
            });
            EventBus.$emit("backtoLoanlist");
          } else {
            EventBus.$emit("showToast", {
              type: "error",
              message: res.data.f100,
              icon: "error",
            });
          }

          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          EventBus.$emit("stoploanDialog", err);
          ("");
          commit("SET_LOADING", false, { root: true });
        });
    },
    ApplyLoan: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            EventBus.$emit("showToast", {
              type: "success",
              message: res.data.f100,
              icon: "success",
            });
            // console.log(res.data.f48)

            EventBus.$emit("loanNo", res.data.f48.application_no);
          } else {
            EventBus.$emit("showToast", {
              type: "error",
              message: res.data.f100,
              icon: "error",
            });
            commit("SET_LOADING", false, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("stoploanDialog", err);
          commit("SET_LOADING", false, { root: true });
        });
    },
    AppraiseLoan: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            // EventBus.$emit("showToast", {
            //   type: "success",
            //   message: res.data.f100,
            //   icon: "success",
            // });

            EventBus.$emit("openDialog", res.data);
            commit("SET_LOADING", false, { root: true });
          } else {
            EventBus.$emit("showToast", {
              type: "error",
              message: res.data.f100,
              icon: "error",
            });
            commit("SET_LOADING", false, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("stoploanDialog", err);
          commit("SET_LOADING", false, { root: true });
        });
    },

    validateChange: ({ commit }, data) => {
      // commit("SET_LOADING", true, { root: true });
      call("post", constants.validate, data)
        .then((res) => {
          commit("MUTATE", { state: "sendToPerson", value: res.data.f48 });
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("stoploanDialog", err);
          commit("SET_LOADING", false, { root: true });
        });
    },

    payLoan: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            EventBus.$emit("showToast", {
              type: "success",
              message: res.data.f100,
              icon: "success",
            });
            EventBus.$emit("paySuccess");
          } else {
            EventBus.$emit("showToast", {
              type: "error",
              message: res.data.f100,
              icon: "error",
            });
          }
          window.location.reload();
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("payFailed", err);
          commit("SET_LOADING", false, { root: true });
        });
    },
    loanSchedule: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            EventBus.$emit("showToast", {
              type: "success",
              message: res.data.f100,
              icon: "success",
            });
            commit("MUTATE", { state: "loanReport", value: res.data.f48 });
            EventBus.$emit("openreportDialog");
          } else {
            EventBus.$emit("showToast", {
              type: "error",
              message: res.data.f100,
              icon: "error",
            });
          }
          // window.location.reload();
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("payFailed", err);
          commit("SET_LOADING", false, { root: true });
        });
    },

    setCurrentTab({ commit }, index) {
      commit("MUTATE", { state: "tabIndex", value: index });
    },

    validateIdNumber: ({ commit }, data) => {
      // commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            EventBus.$emit("showToast", {
              type: "success",
              message: res.data.f100,
              icon: "success",
            });
            commit("MUTATE", { state: "singleGuarantor", value: res.data.f48 });
            EventBus.$emit("validatorSuccess");
          } else {
            EventBus.$emit("showToast", {
              type: "error",
              message: res.data.f100,
              icon: "error",
            });
          }

          EventBus.$emit("closeDialog");
          // commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("stoploanDialog", err);
          commit("SET_LOADING", false, { root: true });
        });
    },

    addGuarantor: ({ commit }, guarantors) => {
      // Set loading state to true before starting the operation
      commit("SET_LOADING", true, { root: true });

      const addGuarantorRecursive = (guarantors) => {
        if (guarantors.length === 0) {
          // No more guarantors to add, do nothing
          return;
        }

        const guarantor = guarantors.shift();
        call("post", constants.guarantorLoan, guarantor)
          .then((res) => {
            if (res.data.f39 === "00") {
              if (guarantors.length === 0) {
                // Last guarantor added successfully, show success message
                EventBus.$emit("showToast", {
                  type: "success",
                  message: "All guarantors added successfully",
                  icon: "success",
                });
              }
              addGuarantorRecursive(guarantors);
              // EventBus.$emit("uploadImage", guarantor.f56);
              EventBus.$emit("uploadImage");
            } else {
              EventBus.$emit("showToast", {
                type: "error",
                message: res.data.f100,
                icon: "error",
              });
              // Stop adding guarantors if any of them fail
              commit("SET_LOADING", false, { root: true });
            }
          })
          .catch((err) => {
            console.log(err)
            EventBus.$emit("showToast", {
              type: "error",
              message: "An error occurred while adding guarantor",
              icon: "error",
            });
            // Stop adding guarantors if any of them fail
            commit("SET_LOADING", false, { root: true });
          });
      };

      addGuarantorRecursive(guarantors);
    },

    getGuarantorRequests: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            commit("MUTATE", {
              state: "guarantorRequests",
              value: res.data.f48,
            });
            EventBus.$emit("validatorSuccess");
          }

          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
    approveRequest: ({ commit, dispatch }, data) => {
      // commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          EventBus.$emit("test", res.data.f48);

          EventBus.$emit("redirectToApproveReject");
          dispatch("getGuarantorRequests", data);

          // EventBus.$emit("redirectPage", res.data.f48);
          // commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("stoploanDialog", err);
          commit("SET_LOADING", false, { root: true });
        });
    },
    rejectRequest: ({ commit, dispatch }, data) => {
      // commit("SET_LOADING", true, { root: true });
      call("post", constants.loans, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            EventBus.$emit("redirectToApproveReject");
            dispatch("getGuarantorRequests", data);

            EventBus.$emit("test", res.data.f48);
            // commit("SET_LOADING", false, { root: true })
          } else {
            EventBus.$emit("showToast", {
              type: "error",
              message: res.data.f100,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("stoploanDialog", err);
          commit("SET_LOADING", false, { root: true });
        });
    },
    validateMoneyOtp: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", constants.loans, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            EventBus.$emit("otp-money-verification");
            commit("SET_LOADING", false, { root: true });
          } else {
            EventBus.$emit("otp-money-close");

            EventBus.$emit("showToast", {
              type: "error",
              message: res.data.f100,
              icon: "error",
            });

            commit("SET_LOADING", false, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("showToast", {
            type: "error",
            message: "Something Went Wrong. Try Again Later",
            icon: "error",
          });
        });
    },
    verifyOtp: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", constants.loans, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            window.localStorage.removeItem("token");
            window.localStorage.setItem("token", res.data.f48.token);

            commit("SET_LOADING", false, { root: true });
            EventBus.$emit("proceed");
            EventBus.$emit("showToast", {
              type: "success",
              message: `${res.data.f100}.Please Wait`,
              icon: "success",
            });
            EventBus.$emit("otp-money-close");
          } else {
            EventBus.$emit("otp-close");
            commit("SET_ALERT", {
              status: "error",
              message: res.data.f100,
            });

            commit("SET_LOADING", false, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong. Try Again Later",
          });
        });
    },
  },
};
