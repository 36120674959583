import routes from "./marketRoutes";
import store from "./marketStore";

export default {
  install(Vue, options) {
    options.router.addRoute("/", ...routes);
    options.store.registerModule("Market", store);
  },
};

