import dashboard from "@/modules/dashboard/views/dashboard";
import bid from "@/modules/market/components/bidPage.vue";
import createBid from "./components/createBid.vue";
import Auth from "@/modules/auth/middleware/Auth";

export default [
  {
    path: "/bid",
    component: dashboard,

    children: [
      {
        path: "/",
        name: "bid",
        component: bid,
        meta: { middleware: [Auth] },
      },
      {
        path: "/create-bid",
        name: "create",
        component: createBid,
        meta: { middleware: [Auth] },
      },
    ],
  },
];
