<template>
  <v-container
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #67e7b3);
    "
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
  >
    <v-alert text class="mt-2" color="teal" icon="mdi-clock-fast" border="left">
      <span class="dash-font-style"
        >Welcome, {{ fullNames ? fullNames : "" }}

        !
      </span>
    </v-alert>

    

    <v-row align="center" justify="center">
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? 12 : 6"
        md="3"
        sm="12"
        class="mt-3"
      >
        <v-card
          flat
          class=""
          color="green"
          :height="$vuetify.breakpoint.mdAndUp ? 120 : 100"
        >
          <v-row>
            <v-card-text
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'mt-0 ml-2 white--text'
                  : 'text-caption font-weight-bold mt-0 ml-2 white--text'
              "
            >
              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'balance-text mt-0 mb-3'
                    : 'text-caption'
                "
              >
                {{ BOSA ? BOSA[0].balances : "N/A" }}</span
              >
              <p></p>

              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'mt-3 text-overline font-weight-bold'
                    : ' text-caption mt-n8'
                "
              >
                {{ BOSA ? BOSA[0].account_name : "" }}</span
              >
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? 12 : 6"
        md="3"
        sm="12"
        class="mt-3"
      >
        <v-card
          flat
          class=""
          color="#17a2d0"
          :height="$vuetify.breakpoint.mdAndUp ? 120 : 100"
        >
          <v-row>
            <v-card-text
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'mt-0 ml-2 white--text'
                  : 'text-caption font-weight-bold mt-0 ml-2 white--text'
              "
            >
              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'balance-text mt-0 mb-3'
                    : 'text-caption'
                "
              >
                {{ BOSA ? BOSA[1].balances : "N/A" }}</span
              >
              <p></p>

              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'mt-3 text-overline font-weight-bold'
                    : ' text-caption mt-n8'
                "
              >
                {{ BOSA ? BOSA[1].account_name : "" }}</span
              >
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? 12 : 6"
        md="3"
        sm="12"
        class="mt-3"
      >
        <v-card
          flat
          class=""
          color="deep-purple"
          :height="$vuetify.breakpoint.mdAndUp ? 120 : 100"
        >
          <v-row>
            <v-card-text
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'mt-0 ml-2 white--text'
                  : 'text-caption font-weight-bold mt-0 ml-2 white--text '
              "
            >
              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'balance-text mt-0 mb-3'
                    : 'text-caption'
                "
              >
                {{ BOSA ? BOSA[2].balances : "N/A" }}</span
              >
              <p></p>

              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'mt-3 text-overline font-weight-bold'
                    : ' text-caption mt-n8'
                "
              >
                {{ BOSA ? BOSA[2].account_name : "" }}</span
              >
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? 12 : 6"
        md="3"
        sm="12"
        class="mt-3"
      >
        <v-card
          flat
          class=""
          color="#BA9913"
          :height="$vuetify.breakpoint.mdAndUp ? 120 : 100"
        >
          <v-row>
            <v-card-text
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'mt-0 ml-2 white--text'
                  : 'text-caption font-weight-bold mt-0 ml-2 white--text'
              "
            >
              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'balance-text mt-0 mb-3'
                    : 'text-caption'
                "
              >
                {{ FOSA ? FOSA.balance : "" }}</span
              >
              <p></p>

              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'mt-3 text-overline font-weight-bold'
                    : ' text-caption mt-n8'
                "
              >
                ORDINARY ACCOUNT</span
              >
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
   

    <v-row align="center" justify="center">
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? 12 : 6"
        md="3"
        sm="12"
        class="mt-3"
      >
        <v-card
          @click="redirect('balances')"
          flat
          class=""
          color="#EDEADE"
          :height="$vuetify.breakpoint.mdAndUp ? 120 : 100"
        >
          <v-row
            ><v-col cols="12" md="6" sm="12" justify="center">
              <v-row>
                <v-card-text
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'text-overline font-weight-bold mt-2 ml-2'
                      : 'text-caption font-weight-bold mt-0 ml-2'
                  "
                >
                  <span class="mt-6 card-text"> My account</span>
                </v-card-text>
              </v-row>
            </v-col>
            <!-- <v-col cols="12" md="1" sm="12"></v-col> -->

            <v-col
              cols="12"
              md="6"
              sm="12"
              :class="
                !$vuetify.breakpoint.mdAndUp ? 'align-end mt-n6 ' : 'align-end mt-6'
              "
            >
              <v-img
                src="../../../assets/prof.png"
                contain
                :height="!$vuetify.breakpoint.mdAndUp ? '60' : '90'"
                class="justify-center mt-n6" /></v-col
          ></v-row>
        </v-card>
      </v-col>
    
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? 12 : 6"
        md="3"
        sm="12"
        class="mt-3"
      >
        <v-card
          @click="redirect('loans')"
          flat
          class=""
          color="#EDEADE"
          :height="$vuetify.breakpoint.mdAndUp ? 120 : 100"
        >
          <v-row
            ><v-col cols="12" md="6" sm="12" justify="center">
              <v-row>
                <v-card-text
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'text-overline font-weight-bold mt-6 ml-2'
                      : 'text-caption font-weight-bold mt-0 ml-2'
                  "
                >
                  <span class="mt-6"> Loans</span>
                </v-card-text>
              </v-row>
            </v-col>
            

            <v-col
              cols="12"
              md="6"
              sm="12"
              :class="
                !$vuetify.breakpoint.mdAndUp ? 'align-end mt-n6 ' : 'align-end mt-6'
              "
            >
              <v-img
                src="../../../assets/loan.png"
                contain
                :height="!$vuetify.breakpoint.mdAndUp ? '60' : '90'"
                class="justify-center mt-n6" /></v-col
          ></v-row>
        </v-card>
      </v-col>

    
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? 12 : 6"
        md="3"
        sm="12"
        class="mt-3"
      >
        <v-card
          @click="redirect('loans')"
          flat
          class=""
          color="#EDEADE"
          :height="$vuetify.breakpoint.mdAndUp ? 120 : 100"
        >
          <v-row
            ><v-col cols="12" md="6" sm="12" justify="center">
              <v-row>
                <v-card-text
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'text-overline font-weight-bold mt-6 ml-2'
                      : 'text-caption font-weight-bold mt-0 ml-2'
                  "
                >
                  <span class="mt-6"> statement</span>
                </v-card-text>
              </v-row>
            </v-col>
            <!-- <v-col cols="12" md="1" sm="12"></v-col> -->

            <v-col
              cols="12"
              md="6"
              sm="12"
              :class="
                !$vuetify.breakpoint.mdAndUp ? 'align-end mt-n6 ' : 'align-end mt-6'
              "
            >
              <v-img
                :height="!$vuetify.breakpoint.mdAndUp ? '60' : '90'"
                src="../../../assets/stat.png"
                contain
                class="justify-center mt-n6" /></v-col
          ></v-row>
        </v-card>
      </v-col>

   
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? 12 : 6"
        md="3"
        sm="12"
        class="mt-3"
      >
        <v-card
          @click="redirect('loyalty')"
          flat
          class=""
          color="#E0E8D7"
          :height="$vuetify.breakpoint.mdAndUp ? 120 : 100"
        >
          <v-row
            ><v-col cols="12" md="6" sm="12" justify="center">
              <v-row>
                <v-card-text
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'text-overline font-weight-bold mt-6 ml-2'
                      : 'text-caption font-weight-bold mt-0 ml-2'
                  "
                >
                  <span class="mt-6"> c-zawadi</span>
                </v-card-text>
              </v-row>
            </v-col>
            <!-- <v-col cols="12" md="1" sm="12"></v-col> -->

            <v-col
              cols="12"
              md="6"
              sm="12"
              :class="
                !$vuetify.breakpoint.mdAndUp ? 'align-end mt-n6 ' : 'align-end mt-6'
              "
            >
              <v-img
                :height="!$vuetify.breakpoint.mdAndUp ? '40' : '70'"
                src="../../../assets/loyalty.png"
                contain
                :class="
                  !$vuetify.breakpoint.mdAndUp
                    ? 'justify-center mt-n6 '
                    : 'justify-center mt-0'
                " /></v-col
          ></v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      ><v-col>
        <v-card class="memberinfo" flat>
          <v-alert class="white--text" align="center" color="#537213">
            <v-icon color="white">mdi-account</v-icon> Member Information
          </v-alert>
          <v-row class="mx-3"
            ><v-col>Member Number:</v-col
            ><v-col>{{
              clientInfo.member_number ? clientInfo.member_number : "N/A"
            }}</v-col></v-row
          >
          <v-divider class="my-3" />
          <v-row class="mx-3"
            ><v-col>Name:</v-col
            ><v-col>{{ fullNames ? fullNames : "" }}</v-col></v-row
          >
          <v-divider class="my-3" />
          <v-row class="mx-3"
            ><v-col>Account Category:</v-col><v-col></v-col
          ></v-row>
          <v-divider class="my-3" />
          <v-row class="mx-3"
            ><v-col>Email Address:</v-col
            ><v-col>{{
              clientInfo.email_address ? clientInfo.email_address : "N/A"
            }}</v-col></v-row
          >
          <v-divider class="my-3" />
          <v-row class="mx-3"
            ><v-col>Phone Number:</v-col
            ><v-col
              >+{{
                clientInfo.phone_number ? clientInfo.phone_number : "N/A"
              }}</v-col
            ></v-row
          >
        </v-card> </v-col
      ><v-col>
        <v-card class="memberinfo" flat>
          <v-alert class="white--text" align="center" color="#537213">
            <v-icon color="white">mdi-format-list-bulleted</v-icon> Mini
            Statement
          </v-alert>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Date</th>
                  <th class="text-left">Description</th>
                  <th class="text-left">Amount(ksh)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, i) in Ministatement
                    ? Ministatement.slice(0, 4)
                    : []"
                  :key="i"
                >
                  <td>{{ item.transaction_date }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.amount }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";
import payload from "@/mixin/payloadMethods";
export default {
  name: "Dashboard",

  data: () => ({
    colors: [
      "green",
      "secondary",
      "yellow darken-4",
      "red lighten-2",
      "orange darken-1",
    ],
    cycle: true,
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      // v.$store.dispatch("Auth/getImages");

      const data = {
        mti: "0200",
        f2: userInfo.phone_number,
        f3: "330000",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f60: "KES",
        f68: "Free Account Balance Enquiry",
        f90: userInfo.customer_id,
        f91: "FOSA",
        f123: "WEB",
        f92: userInfo.member_number,
      };

      v.$store.dispatch("Profile/getFreeBalances", data);

      const miniData = {
        mti: "0200",
        f2: userInfo.phone_number,
        f3: "380000",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f68: "Mini Statement",
        f90: userInfo.customer_id,
        f123: "WEB",
        f92: userInfo.member_number,
        f93: userInfo.account_number,
      };

      v.$store.dispatch("Profile/getMiniStatement", miniData);
    });
  },

  computed: {
    Images() {
      return this.$store.getters["Auth/authGetters"]("images");
    },
    clientInfo() {
      return userInfo;
    },
    freeBalances() {
      return this.$store.getters["Profile/profileGetters"]("freeBalances");
    },
    Ministatement() {
      return this.$store.getters["Profile/profileGetters"]("Ministatement");
    },
    BOSA() {
      return this.freeBalances !== undefined
        ? this.freeBalances.account_balances_bosa
        : [];
    },
    FOSA() {
      return this.freeBalances.account_balances_fosa
        ? this.freeBalances.account_balances_fosa[0]
        : {};
    },

    fullNames() {
      return `${userInfo.first_name}${""} ${userInfo.second_name}${""} ${
        userInfo.last_name
      }`;
    },
  },
  watch: {},

  methods: {
    redirect(val) {
      this.$router.push({ name: val });
    },
  },
};
</script>

<style scoped>
@import url("../styles.css");
</style>
