<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
    class=""
  >
    <!-- <v-card elevation="2" class="mt-6"> -->

    <v-row class="mt-4">
      <v-card class="mx-auto" width="800" flat>
        <v-card-title>
          <span class="title-font-style">Change Pin</span>
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-form v-model="isValid" ref="transferForm" v-on:submit.prevent>
            <span
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'text-overline new-main'
                  : 'mob-main-font-style'
              "
            >
              Enter Your Current Pin</span
            >
            <v-text-field
              v-model="formData.oldPin"
              outlined
              dense
              type="number"
              :rules="rules.required"
              label="current pin"
              class="small-label"
            ></v-text-field>

            <span
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'text-overline new-main'
                  : 'mob-main-font-style'
              "
            >
              Enter Your New Pin</span
            >

            <v-text-field
              v-model="formData.newPin"
              outlined
              dense
              type="number"
              :rules="rules.required"
              label="new pin"
            ></v-text-field>

            <span
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'text-overline new-main'
                  : 'mob-main-font-style'
              "
            >
              Confirm New Pin</span
            >

            <v-text-field
              v-model="formData.confPin"
              outlined
              dense
              type="number"
              :rules="rules.required"
              label="confirm pin"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="">
          <v-spacer />

          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            color="primary white--text"
            @click="changePin()"
          >
            <span>Submit</span>
          </v-btn>
          <v-btn
            v-if="!$vuetify.breakpoint.mdAndUp"
            small
            @click="changePin()"
            color="primary white--text"
          >
            <span>Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";

import payload from "@/mixin/payloadMethods";
import { EventBus } from "@/utils/eventBus";
export default {
  name: "changePin",
  components: {},

  data() {
    return {
      isValid: false,

      destinations: [],
      formData: {
        oldPin: "",
        newPin: "",
        confPin: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
    };
  },

  computed: {},
  methods: {
    changePin() {
      if (!this.isValid) {
        this.$refs.transferForm.validate();
      } else {
        const data = {
          mti: "0100",
          f0: "1",
          f2: userInfo.phone_number,
          f3: "101013",
          f7: payload.methods.getTransactionDate(),
          f11: payload.methods.getMinuteSecond(),
          f12: payload.methods.getHourMinuteSecond(),
          f13: payload.methods.getHourMinute(),
          f37: payload.methods.createRefno(),
          f56: payload.methods.generatePasswordHash(
            this.formData.newPin,
            userInfo.phone_number
          ), //new pin
          f60: "KES",
          f64: payload.methods.generatePasswordHash(
            this.formData.oldPin,
            userInfo.phone_number
          ), //oldpin
          f68: "Change PIN",
          f90: userInfo.customer_id,
          f123: "WEB",
        };

        this.$store.dispatch("Profile/changePin", data);
      }
    },
  },
  watch: {
    bosa: {
      handler: function () {
        if (this.bosa.length > 0) {
          this.destinations = [...this.bosa, ...this.fosa];
        }
      },
    },
  },
  mounted() {
    EventBus.$on("successChange", (val) => {
      if (val === "SUCCESS") {
        this.$toast.success(val);
        this.$refs.transferForm.reset();
      } else {
        this.$toast.error(val);
      }
    });
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
