<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center main-cont"
    color="#26c6da"
    v-on:keydown.enter="Register('firstTime')"
  >
    <v-row :class="$vuetify.breakpoint.mdAndUp ? 'mt-n3' : 'mt-n5'">
      <v-col cols="12" md="12" sm="12" class="justify-center" align="center">
        <v-img
          src="../../../assets/chuna.png"
          height="100"
          contain
          class="justify-center"
        />
        <span class="title-logo">CHUNA DT SACCO</span>
      </v-col>
      <v-col md="6" sm="10" offset-md="3">
        <v-sheet elevation="2" class="pa-3">
          <v-row>
            <v-col cols="12" md="12">
              <v-card flat>
                <v-alert
                  dense
                  v-if="$store.getters['Auth/alert'].status"
                  :type="
                    $store.getters['Auth/alert'].status === 'success'
                      ? 'success'
                      : 'error'
                  "
                  class="mb-10"
                >
                  {{ $store.getters["Auth/alert"].message }}
                </v-alert>

                <v-btn
                  @click="redirect('Login')"
                  elevation="0"
                  color="primary"
                  ><v-icon small>mdi-arrow-left</v-icon>Back
                </v-btn><br/>
                <!-- <span class="welcome-text"> Welcome !</span><br /> -->

                <!-- <span class="welcome-text"> Register Below</span> -->

                <v-card-text class="text-justify mt-2 pb-0">
                  <v-col justify="center" align="center"> <span class="text-overline font-weight-black" > Register Below</span></v-col>
                 
                  
                  <v-form v-model="isValid" ref="loginForm" v-on:submit.prevent>
                    <v-row
                      ><v-col>
                        <v-text-field
                          dense
                          outlined
                          label="Full Names"
                          placeholder="Enter your Full Names"
                          v-model="formData.names"
                          ref="names"
                          :rules="rules.required" />
                        <v-text-field
                          dense
                          outlined
                          label="ID Number"
                          placeholder="Enter your ID Number"
                          v-model="formData.idNo"
                          ref="idNo"
                          :rules="rules.required" />
                        <v-text-field
                          dense
                          outlined
                          label="Company"
                          placeholder="Enter your Company"
                          v-model="formData.company"
                          ref="company"
                          :rules="rules.required"
                      /></v-col>
                      <v-col>
                        <v-text-field
                          dense
                          outlined
                          label="Phone"
                          placeholder="Enter your Phone Number"
                          v-model="formData.phone"
                          ref="phone"
                          :rules="rules.required"
                        />
                        <v-text-field
                          dense
                          outlined
                          label="Email (Optional)"
                          placeholder="Email"
                          v-model="formData.email"
                          ref="email"
                        />
                        <v-text-field
                          dense
                          outlined
                          label="Position"
                          placeholder="Enter your Position "
                          v-model="formData.position"
                          ref="position"
                          :rules="rules.required"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-select
                        dense
                        :items="items"
                        class="mx-4"
                        outlined
                        label="Employment Type"
                        placeholder="Select Employment Type "
                        :item-value="(item) => item.id"
                        :item-text="(item) => item.name"
                        v-model="formData.level"
                        ref="level"
                        :rules="rules.required"
                    /></v-row>

                    <v-row
                      ><v-col>
                        <v-text-field
                          dense
                          outlined
                          label="Gross Salary"
                          placeholder="Gross Salary "
                          v-model="formData.gross"
                          ref="gross"
                          :rules="rules.required" />
                        <v-text-field
                          dense
                          outlined
                          label=" Desired Loan Amount"
                          placeholder=" Loan Amount "
                          v-model="formData.loan"
                          ref="loan"
                          :rules="rules.required" /></v-col
                      ><v-col>
                        <v-text-field
                          dense
                          outlined
                          label="Net Salary"
                          placeholder="Net Salary "
                          v-model="formData.net"
                          ref="net"
                          :rules="rules.required" />
                        <v-text-field
                          dense
                          outlined
                          label=" Repayment Period"
                          placeholder=" Repayment Period "
                          v-model="formData.period"
                          ref="period"
                          :rules="rules.required" /></v-col
                    ></v-row>
                  </v-form>
                </v-card-text>

                <v-card-actions class="">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-btn
                        class="btn-success btn-block white--text"
                        color="#E21C21"
                        @click="Register('firstTime')"
                      >
                        <span>Register</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>

                <div class="alert text-muted">
                  Already Have an account ?
                  <v-btn
                    @click="redirect('Login')"
                    text
                    class="btn-linda-mama pink--text"
                    >Login Here
                  </v-btn>
                </div>

                <div class="alert text-muted mt-n6">
                  For queries ,call us at
                  <strong> 0705951672 / 0733809421 </strong>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="600" persistent>
      <v-card class="pa-7" tile elevation="5">
        <v-card-title class="d-flex flex-row justify-center">
          Please Wait as we log you in
        </v-card-title>

        <v-alert
          outlined
          v-if="$store.getters['Auth/alert'].status"
          dense
          border="left"
          :type="
            $store.getters['Auth/alert'].status === 'success'
              ? 'success'
              : 'error'
          "
          class="mb-10"
        >
          {{ $store.getters["Auth/alert"].message }}
        </v-alert>

        <v-card-text class="d-flex flex-row justify-center">
          <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="red" @click="dialog = false"> Close </v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
      <Spinner />
    </v-dialog>
    <v-snackbar v-model="snackbar">
      Session Ended. Please Login Again.

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
import Spinner from "@/plugins/loader/views/Spinner";

import payload from "@/mixin/payloadMethods";

export default {
  name: "register",
  components: { Spinner },
  beforeRouteEnter(to, from, next) {
    next(() => {
      // v.$store.dispatch("Auth/getImages", {});
    });
  },
  data: function () {
    return {
      needHelpText: "Need Help",
      locationText: "Location",
      snackbar: false,
      isValid: false,
      isOtpValid: false,
      dialog: false,
      phone: "", // Initialize phone, pin, and newPin with your data
      pin: "",
      newPin: "",
      refno: "",
      formData: {
        period: "",
        loan: "",
        net: "",
        level: "",
        gross: "",
        position: "",
        company: "",
        idNo: "",
        names: "",
      },

      items: [
        { id: 1, name: "Permanent" },
        { id: 2, name: "Contract" },
        { id: 3, name: "Casual" },
      ],
      rules: {
        required: [(value) => !!value || "Required."],
      },
      colors: [
        "green",
        "secondary",
        "yellow darken-4",
        "red lighten-2",
        "orange darken-1",
      ],
      cycle: true,
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },

  mounted() {
    EventBus.$on("registersuccess", (val) => {
      if (val === "SUCCESS") {
        this.$toast.success(val);
        this.$refs.loginForm.reset();
      } else {
        this.$toast.error(val);
      }
    });
  },

  computed: {
    user() {
      return this.$store.getters["Auth/authGetters"]("user");
    },
    newData() {
      return this.$store.getters["Auth/authGetters"]("newData");
    },
    Images() {
      return this.$store.getters["Auth/authGetters"]("images");
    },
  },
  methods: {
    Register: function (val) {
      if (val === "firstTime") {
        if (!this.isValid) {
          this.$refs.loginForm.validate();
        } else {
          const newPhone = this.formData.phone.replace(/^0|254/g, "");
          const NP = "254" + newPhone;
          const data = {
            mti: "0100",
            f0: "0",
            f2: NP,
            f3: "101019",
            f7: payload.methods.getTransactionDate(),
            f11: payload.methods.getMinuteSecond(),
            f12: payload.methods.getHourMinuteSecond(),
            f13: payload.methods.getHourMinute(),
            f37: payload.methods.createRefno(),
            f60: this.formData.names, //FULL NAME
            f61: this.formData.idNo, // ID NUMBER
            f62: this.formData.company, // COMPANY
            f63: this.formData.position, // POSITION IN THE COMPANY
            f64: this.formData.level, //PERMANENt, 2.contract, 3.casual
            f65: this.formData.gross, //gross salary
            f66: this.formData.net, // net salary
            f67: this.formData.loan, // desired loan amount
            f69: this.formData.period, //repayment period in months
            f68: "Registration Request",
            f123: "WEB",
          };

          this.$store.dispatch("Auth/register", data);
        }
      }
    },
    redirect(val) {
      this.$router.push({ name: val });
    },
  },
};
</script>

<style scoped>
@import url("../styles.css");

.v-input__append-outer {
  margin-top: 8px;
}
</style>
