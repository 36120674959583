import { render, staticRenderFns } from "./sourceDashboard.vue?vue&type=template&id=48c7ef1d&scoped=true&"
import script from "./sourceDashboard.vue?vue&type=script&lang=js&"
export * from "./sourceDashboard.vue?vue&type=script&lang=js&"
import style0 from "./sourceDashboard.vue?vue&type=style&index=0&id=48c7ef1d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c7ef1d",
  null
  
)

export default component.exports