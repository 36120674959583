import dashboard from "@/modules/dashboard/views/dashboard";
import loyalty from "@/modules/loyalty/components/loyalty";
import Auth from "@/modules/auth/middleware/Auth";

export default [
  {
    path: "/loyalty",
    component: dashboard,

    children: [
      {
        path: "/",
        name: "loyalty",

        component: loyalty,
        meta: { middleware: [Auth] },
      },
    ],
  },
];
