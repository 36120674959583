<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
  >
   
      <v-card class="mx-auto mt-3" flat>
        <v-card-title>
          <v-btn color="primary" text :to="{ name: 'mainDashboard' }">
            <v-icon>mdi-arrow-left</v-icon>
            <span>Back</span>
          </v-btn>
        </v-card-title>
        <v-card-title>
          <span class="title-font-style">C-ZAWADI</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="5" sm="12" class="align-center" v-if="$vuetify.breakpoint.mdAndUp">
              <v-img
                src="../../../assets/customer-loyalty.png"
                contain
                class="justify-center mt-n6"
            /></v-col>
            <v-col cols="12" md="7" sm="12" class="align-center">
              <v-row>
                <v-col>
                  <v-card
                    flat
                    class=""
                    color="#EDEADE"
                    :height="$vuetify.breakpoint.mdAndUp ? 120 : 100"
                  >
                    <v-row
                      ><v-col
                        align="center"
                        justify="center"
                        cols="12"
                        md="12"
                        sm="12"
                      >
                        <v-row>
                          <v-card-text
                            :class="
                              $vuetify.breakpoint.mdAndUp
                                ? 'text-overline font-weight-bold red--text mt-2 ml-2'
                                : 'text-subtitle-2 green--text mt-0 ml-2'
                            "
                          >
                            <span class="mt-0"> C-Zawadi Account</span>
                          </v-card-text>
                        </v-row>
                      </v-col>

                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        align="center"
                        justify="center"
                        class="text-button mt-n4"
                      
                      >
                        {{ loyalty ? loyalty.loyalty_account : "N/A" }}</v-col
                      ></v-row
                    >
                  </v-card>
                  <v-card
                    flat
                    class="mt-8"
                    color="#EDEADE"
                    :height="$vuetify.breakpoint.mdAndUp ? 120 : 100"
                  >
                    <v-row
                      ><v-col
                        align="center"
                        justify="center"
                        cols="12"
                        md="12"
                        sm="12"
                      >
                        <v-row>
                          <v-card-text
                            :class="
                              $vuetify.breakpoint.mdAndUp
                                ? 'text-overline font-weight-bold red--text mt-2 ml-2'
                                : 'text-subtitle-2 green--text  mt-0 ml-2'
                            "
                          >
                            <span class="mt-0"> Actual Balance</span>
                          </v-card-text>
                        </v-row>
                      </v-col>

                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        align="center"
                        justify="center"
                        class="text-button mt-n4"
                      
                      >
                        {{ loyalty ? loyalty.actual_balance : "N/A" }}</v-col
                      ></v-row
                    >
                  </v-card>
                </v-col>
                <v-col>
                  <v-card
                    flat
                    color="#EDEADE"
                    :height="$vuetify.breakpoint.mdAndUp ? 120 : 100"
                  >
                    <v-row
                      ><v-col
                        align="center"
                        justify="center"
                        cols="12"
                        md="12"
                        sm="12"
                      >
                        <v-row>
                          <v-card-text
                            :class="
                              $vuetify.breakpoint.mdAndUp
                                ? 'text-overline font-weight-bold red--text mt-2 ml-2'
                                : 'text-subtitle-2 green--text mt-0 ml-2'
                            "
                          >
                            <span class="mt-0"> Account Type</span>
                          </v-card-text>
                        </v-row>
                      </v-col>

                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        align="center"
                        justify="center"
                        class="text-button mt-n4"
                       
                      >
                        {{ loyalty ? loyalty.account_type : "N/A" }}</v-col
                      ></v-row
                    >
                  </v-card>

                  <v-card
                    flat
                    class="mt-8"
                    color="#EDEADE"
                    :height="$vuetify.breakpoint.mdAndUp ? 120 : 100"
                  >
                    <v-row
                      ><v-col
                        align="center"
                        justify="center"
                        cols="12"
                        md="12"
                        sm="12"
                      >
                        <v-row>
                          <v-card-text
                            :class="
                              $vuetify.breakpoint.mdAndUp
                                ? 'text-overline font-weight-bold red--text mt-2 ml-2'
                                : 'text-subtitle-2 green--text mt-0 ml-2'
                            "
                          >
                            <span class="mt-0"> Available Balance</span>
                          </v-card-text>
                        </v-row>
                      </v-col>

                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        align="center"
                        justify="center"
                        class="text-button mt-n4"
                       
                      >
                        {{ loyalty ? loyalty.available_balance : "N/A" }}</v-col
                      ></v-row
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  
  </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";
import payload from "@/mixin/payloadMethods";
export default {
  name: "Loyalty",

  beforeRouteEnter(to, from, next) {
    next(() => {});
  },
  data: function () {
    return {
      dialog: true,
      headers: [
        {
          text: "Loan No",
          align: "start",
          sortable: false,
          value: "loan_no",
        },
        { text: "Loan Name", value: "loan_name" },
        { text: "Loan Amount", value: "loan_installment" },
        { text: "Application Date", value: "loan_application_date" },

        { text: "Issue Date", value: "loan_issue_date" },
      ],
    };
  },

  computed: {
    loyalty() {
      return this.$store.getters["loyalty/loyaltyGetters"]("loyalty");
    },
  },
  methods: {},
  watch: {},
  mounted() {
    const data = {
      mti: "0200",
      f2: userInfo.phone_number,
      f3: "311000",
      f4: "0",
      f7: payload.methods.getTransactionDate(),
      f11: payload.methods.getMinuteSecond(),
      f12: payload.methods.getHourMinuteSecond(),
      f13: payload.methods.getHourMinute(),
      f37: payload.methods.createRefno(),
      f60: "KES",
      f68: "C-ZAWADI Balance",
      f90: userInfo.customer_id,
      f123: "WEB",
    };

    this.$store.dispatch("loyalty/getLoyalties", data);
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
