import dashboard from "@/modules/dashboard/views/dashboard";
import balances from "@/modules/profile/components/balances";
import account from "@/modules/profile/components/statements";
import Auth from "@/modules/auth/middleware/Auth";

export default [
  {
    path: "/my-account",
    component: dashboard,

    children: [
      {
        path: "/",
        name: "balances",
        component: balances,
        meta: { middleware: [Auth]},
      },
      {
        path: "/account/:code",
        name: "account",
        component: account,
        meta: { middleware: [Auth]},
      },
    ],
  },
];

