import constants from "./sourceConstants";
import call from "@/service/http";
// import { EventBus } from "@/utils/eventBus";

export default {
  namespaced: true,
  state: {
    sourceAccs: [],
    accTypes: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    sourceGetters: (state) => (val) => state[val],
  },
  actions: {
    getSourceAccs: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.sourceAccs, data)
        .then((res) => {
          commit("MUTATE", { state: "sourceAccs", value: res.data.f48 });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
    getAccTypes: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.sourceAccs, data)
        .then((res) => {
          commit("MUTATE", { state: "accTypes", value: res.data.f48 });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
  },
};
