import call from "@/service/http";
import AuthConstants from "./authConstants";
import AuthService from "./authService";
// import clientInfo from "./jwtToken";
import { EventBus } from "@/utils/eventBus";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    images: [],
    newData: {},
    user: {},
    alert: {
      status: "",
      message: "",
    },
  },
  mutations: {
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    alert: (state) => state.alert,
    authGetters: (state) => (val) => state[val],
  },
  actions: {
    login: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", AuthConstants.login, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            commit("MUTATE", { state: "user", value: res.data.f48 });
            EventBus.$emit("otp-verification");
            AuthService.setUser(res.data.f48);
            commit("SET_LOADING", false, { root: true });
          } else {
            EventBus.$emit("otp-close");
            commit("SET_ALERT", {
              status: "error",
              message: res.data.f100,
            });
            commit("SET_LOADING", false, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong. Try Again Later",
          });
        });
    },

    getImages: ({ commit } ,data) => {
      axios
        .post(
          "https://netbanking.chunasacco.co.ke/app/api", data
        )
        .then((res) => {
          console.log(res)
          commit("MUTATE", { state: "images", value: res.data.f48 });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hashPin: ({ commit }, data) => {
      axios
        .post("https://netbanking.chunasacco.co.ke/app/chuna_app_api.php", data)
        .then((res) => {
          commit("MUTATE", { state: "newData", value: res.data });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    validatePin: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", AuthConstants.validatePin, data.validate)
        .then((res) => {
          if (res.data.f39 === "00") {
            // AuthService.login(res.data.f48.token);

            commit("SET_LOADING", false, { root: true });
          } else {
            EventBus.$emit("otp-close");
            commit("SET_ALERT", {
              status: "error",
              message: res.data.f100,
            });
          }

          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong. Try Again Later",
          });
        });
    },

    register: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.login, data)
        .then((res) => {
          EventBus.$emit("registersuccess", res.data.f100);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong. Try Again Later",
          });
          commit("SET_LOADING", false, { root: true });
        });
    },
    validateOtp: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", AuthConstants.login, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            AuthService.login(res.data.f48.token);

            commit("SET_LOADING", false, { root: true });
          } else {
            EventBus.$emit("otp-close");
            commit("SET_ALERT", {
              status: "error",
              message: res.data.f100,
            });
            
            commit("SET_LOADING", false, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong. Try Again Later",
          });
        });
    },
    resendOtp: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", AuthConstants.login, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            commit("SET_ALERT", {
              status: "success",
              message: res.data.f100,
            });
            // AuthService.login(res.data.f48.token);

            commit("SET_LOADING", false, { root: true });
          } else {
            EventBus.$emit("otp-close");
            commit("SET_ALERT", {
              status: "error",
              message: res.data.f100,
            });
            
            commit("SET_LOADING", false, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong. Try Again Later",
          });
        });
    },
  },
};
