<template>
    <v-container :fluid="$vuetify.breakpoint.mdAndUp ? true : false" style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    " class="">
        <!-- <v-card elevation="2" class="mt-6"> -->

        <v-row class="mt-4">
            <v-card class="mx-auto" width="800" flat>
                <v-card-title>
                    <span class="title-font-style">Create Bid</span>
                </v-card-title>
                <v-divider />

                <v-card-text>
                    <v-form v-model="isBid" ref="repaymentForm" v-on:submit.prevent>
                        <v-text-field dense outlined label="Amount to Pay" placeholder="Enter Amount to Pay"
                            v-model="formData.amount" :rules="rules.numberValidationRule" class="small-label" />
                    </v-form>
                </v-card-text>

                <v-card-actions class="">
                    <v-spacer />

                    <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="primary white--text">
                        <span>Create</span>
                    </v-btn>
                    <v-btn v-if="!$vuetify.breakpoint.mdAndUp" small color="primary white--text">
                        <span>Create</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-row>

        <v-dialog v-model="otpDialog" width="400" persistent>
            <v-card height="150">
             
                <v-card-title class="text-button text-center">
                    <v-spacer />
                    Proceed to create bid ?
                    <v-spacer />

                </v-card-title>

                <v-card-actions class="mt-4">
                    <v-btn  small color="error" class="white--text"> Cancel </v-btn>
                    <v-spacer />
                    <v-btn small color="success" class="white--text"> Proceed </v-btn>

               
                </v-card-actions>
            </v-card>
            <Spinner />
        </v-dialog>
    </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";

import payload from "@/mixin/payloadMethods";

import Spinner from "@/plugins/loader/views/Spinner";
export default {
    name: "mobiletransfer",
    components: { Spinner },

    beforeRouteEnter(to, from, next) {
        next(() => { });
    },
    data() {
        return {
            isBid: false,
            otpDialog: false,
            formData: {
                amount: "",
            },

            rules: {
                numberValidationRule: [
                    (value) => !!value || "Required.",
                    (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
                    (value) =>
                        (value && parseInt(value) >= 50) || "Value must not be below 50",
                ],
            },
        };
    },

    computed: {},
    methods: {
        sendMoney() {

            const data = {
                mti: "0200",
                f0: "1",
                f2: userInfo.phone_number,
                f3: "210000",
                f4: this.formData.amount,
                f7: payload.methods.getTransactionDate(),
                f11: payload.methods.getMinuteSecond(),
                f12: payload.methods.getHourMinuteSecond(),
                f13: payload.methods.getHourMinute(),
                f37: payload.methods.createRefno(),
                f60: "KES",
                // f65: this.formData.from.account_no,
                f68: "Sell Shares Request",
                f90: userInfo.customer_id,
                f93: this.user.account_number,
                f123: "WEB",
                f92: userInfo.member_number

            };

            this.$store.dispatch("destination/sendtoMobile", data);
        },
    },
    watch: {},
    mounted() { },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
