import dashboard from "@/modules/dashboard/views/dashboard";
import Ministatement from "@/modules/statement/components/Ministatement";
import account from "@/modules/profile/components/statements";
import Auth from "@/modules/auth/middleware/Auth";

export default [
  {
    path: "/statement",
    component: dashboard,

    children: [
      {
        path: "/mini-statement",
        name: "Ministatement",
        component: Ministatement,
        meta: { middleware: [Auth]},
      },
      {
        path: "/account/:code",
        name: "account",
        component: account,
        meta: { middleware: [Auth]},
      },
    ],
  },
];

