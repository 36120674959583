export default {
  login_mti: "0100",
  login_code: "201010",
  login_text: "Login Request",
  customer_code: "101010",
  customer_details_text: "Get Customer Details",
  change_pin: "101013",
  change_text: "Change PIN",
  acc_balance_code: "330000",
  acc_balance_narration: "Account Balance Enquiry",
  loan_balance_code: "611000",
  loan_balance_narration: "Loan Balance Enquiry",
  statement_code: "382000",
  statement_narration: "Ministatement Enquiry",
  src_acc_code: "",
  src_acc_narration: "",
  dest_acc_code: "",
  desr_acc_text: "",
};
