<template>
    <v-container
      :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
      style="
        height: 100%;
        background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
      "
    >
      <v-card class="mx-auto mt-3" flat>
        <v-card-title>
          <v-btn color="primary" text :to="{ name: 'loans' }">
            <v-icon>mdi-arrow-left</v-icon>
            <span>Back</span>
          </v-btn>
        </v-card-title>
        <v-card-title>
          <span class="title-font-style">Detailed Statement </span>
          <v-spacer />
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="formData.from"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formData.from"
                label="From"
                prepend-icon="mdi-calendar"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="formData.from" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(formData.from)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formData.from"
                label="Picker without buttons"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              scrollable
              v-model="formData.to"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formData.to"
                label="Picker without buttons"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              scrollable
              v-model="formData.to"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-card-title>
        <iframe
          :src="base64PDF ? base64PDF : ''"
          style="width: 100%; height: 800px"
        ></iframe>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import userInfo from "@/modules/auth/clientInfo";
  import payload from "@/mixin/payloadMethods";
  export default {
    name: "LoanStatement",
  
    beforeRouteEnter(to, from, next) {
      next((v) => {
        const data = {
          mti: "0200",
          f2: userInfo.phone_number,
          f3: "500020",
          f7: payload.methods.getTransactionDate(),
          f11: payload.methods.getMinuteSecond(),
          f13: payload.methods.getHourMinute(),
          f12: payload.methods.getHourMinuteSecond(),
          f37: payload.methods.createRefno(),
          f68: "Get Loan Statement",
          f90: userInfo.customer_id,
          f123: "WEB",
          f92: userInfo.member_number,
        };
  
        v.$store.dispatch("loans/getLoanStatement", data);
      });
    },
    data: function () {
      return {
        base64PDF: "",
        menu: false,
        menu2: false,
        formData: {
          from: "",
          to: "",
        },
      };
    },
  
    computed: {
      statement() {
        return this.$store.getters["loans/loansGetters"]("statement");
      },
      pdfFile() {
        return this.statement.base_report;
      },
    },
    methods: {
      encodeString(val) {
        const encodedData = btoa(val);
        return encodedData;
      },
    },
    watch: {
      pdfFile: {
        handler: function () {
          if (this.pdfFile) {
            this.base64PDF = `data:application/pdf;base64,${this.pdfFile}`;
          }
        },
      },
    },
  };
  </script>
  
  <style scoped>
  @import url("../../profile/style.css");
  </style>
  