<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
  >
    <v-card class="mx-auto mt-3" flat>
      <v-card-title>
        <v-btn color="primary" text :to="{ name: 'balances' }">
          <v-icon>mdi-arrow-left</v-icon>
          <span>Back</span>
        </v-btn>
      </v-card-title>
      <v-card-title>
        <span class="title-font-style">Destination Accounts</span>
      </v-card-title>

      <!-- <v-card class="mt-8"> -->
      <v-data-table
        :headers="headers"
        :items="destinations ? destinations : []"
        :items-per-page="11"
        class="elevation-0"
        :mobile-breakpoint="0"
      >
      </v-data-table>
      <!-- </v-card> -->
    </v-card>
  </v-container>
</template>

<script>
// import { EventBus } from "@/utils/eventBus";
import userInfo from "@/modules/auth/clientInfo";
import payload from "@/mixin/payloadMethods";
export default {
  name: "destination",

  beforeRouteEnter(to, from, next) {
    next(() => {});
  },
  data: function () {
    return {
      headers: [
        {
          text: "Loan No",
          align: "start",
          sortable: false,
          value: "loan_no",
        },
        { text: "Loan Name", value: "loan_name" },
        { text: "Loan Amount", value: "loan_installment" },
        { text: "Application Date", value: "loan_application_date" },

        { text: "Issue Date", value: "loan_issue_date" },
      ],
    };
  },

  computed: {
    destinations() {
      return this.$store.getters["destination/destinationGetters"]("destinations");
    },
  },
  methods: {},
  watch: {},
  mounted() {
    const data = {
      f60: "KES",
      f13: payload.methods.getHourMinute(),
      f12: payload.methods.getHourMinuteSecond(),
      f92: userInfo.member_number,
      mti: "0100",
      f11: payload.methods.getMinuteSecond(),
      f68: "GET DESTINATION ACCOUNT FOR WEB",
      f2: userInfo.phone_number,
      f37: payload.methods.createRefno(),
      f123: "WEB",
      f3: "432200",
      f7: payload.methods.getTransactionDate(),
      f90: userInfo.customer_id,
    };

    this.$store.dispatch("destination/getDestinationAccounts", data);
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
