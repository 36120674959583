import constants from "./loyaltyConstants";
import call from "@/service/http";

export default {
  namespaced: true,
  state: {
    loyalty: {},
   
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    loyaltyGetters: (state) => (val) => state[val],
  },

  actions: {
    getLoyalties: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.loyalty, data)
        .then((res) => {
          commit("MUTATE", { state: "loyalty", value: res.data.f48 });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
   
  },
};
