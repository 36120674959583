export default {
  methods: {
    getStatusColor(status) {
      // Add logic to determine class based on status
      if (status === "Approved") {
        return "success";
      } else if (status === "Rejected") {
        return "error";
      } else if (status === "Pending") {
        return "black";
      } else {
        // Default class if status does not match any condition
        return "default";
      }
    },
    formatNumber(number) {
      const formattedNumber = parseFloat(number).toLocaleString();

      return formattedNumber;
    },
  },
};
