<template>
    <v-container
      :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
      style="
        height: 100%;
        background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
      "
    >
      <v-card class="mx-auto mt-3" flat>
        <v-card-title>
          <v-btn color="primary" text :to="{ name: 'loans' }">
            <v-icon>mdi-arrow-left</v-icon>
            <span>Back</span>
          </v-btn>
        </v-card-title>
        <v-card-title>
          <span class="title-font-style">Loans Guaranteed </span>
        </v-card-title>
        <iframe style="width: 98%; height: 700px" :src="base64PDF ? base64PDF : ''" class="iframe ml-4" v-if="base64PDF"></iframe>
     
      </v-card>
    </v-container>
  </template>
  
  <script>
  import userInfo from "@/modules/auth/clientInfo";
  import payload from "@/mixin/payloadMethods";
  export default {
    name: "LoansGuaranteed",
  
    beforeRouteEnter(to, from, next) {
      next((v) => {
        const data = {
          mti: "0200",
          f2: userInfo.phone_number,
          f3: "500000",
          f7: payload.methods.getTransactionDate(),
          f11: payload.methods.getMinuteSecond(),
          f13: payload.methods.getHourMinute(),
          f12: payload.methods.getHourMinuteSecond(),
          f37: payload.methods.createRefno(),
          f68: "LoansGuaranteed",
          f90: userInfo.customer_id,
          f123: "WEB",
          f92: userInfo.member_number,
        };
  
        v.$store.dispatch("loans/getLoanStatement", data);
      });
    },
    data: function () {
      return {
        base64PDF: "",
      };
    },
  
    computed: {
      statement() {
        return this.$store.getters["loans/loansGetters"]("statement");
      },
      pdfFile() {
        return this.statement.base_report;
      },
  
    },
    methods: {
      encodeString(val) {
        const encodedData = btoa(val);
        return encodedData;
      },
    },
    watch: {
      pdfFile: {
        handler: function () {
          if (this.pdfFile) {
            this.base64PDF = `data:application/pdf;base64,${this.pdfFile}`;
          }
        },
      },
    },
   
  };
  </script>
  
  <style scoped>
  @import url("../../profile/style.css");
  </style>
  