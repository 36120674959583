<template>
  <v-app>
    <!--  Side Bar  -->
    <v-navigation-drawer dark class="px-1 drawerColor" app clipped v-model="drawer"
      :style="{ top: $vuetify.application.top + 'px', zIndex: 4 }">
      <v-divider></v-divider>

      <div class="">
        <ul nav>
          <span class="nav-link" v-for="(item, index) in navLinks" :key="item.title" :to="item.to" :href="item.href"
            :disabled="item.disabled">
            <v-list-item-title class="nav-title white--text mb-4 mt-4"
              :class="{ 'selected-row': item.title === selectedMenu }" @click="item.to !== '' ? navigate(item.to) : ''">
              <v-row @click="!show ? openSubMenus(item, index) : close(item)" class="active-background">
                <v-col cols="6">
                  <v-list-item-icon class="ml-4">
                    <v-icon small>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <span class="ml-n6">
                    <span>{{ item.title }}</span>
                  </span>
                </v-col>
                <v-col cols="6" class="" align="end">
                  <v-icon class="mr-6 mt-4" small v-if="item.children.length > 0">{{
                    show && item.title === selectedMenu
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                  }}</v-icon>
                </v-col>
              </v-row>
              <v-divider />
            </v-list-item-title>
            <ul v-if="show && item.title === selectedMenu" class="menu-list ml-6">
              <li v-for="(item, i) in item.children" :key="i" class="active-background ">
                <v-list-item-title @click="navigate(item.to)"
                  :class="item.to === currentRoute ? 'active-sub-nav-title' : 'sub-nav-title white--text'">
                  <v-list-item-icon class="ml-0 title-icon">
                    <v-icon small class="hover-icon" :color="item.to === currentRoute ? '#fcc019' : ''">{{ item.icon
                      }}</v-icon>
                  </v-list-item-icon>
                  <span class="ml-n6 sub-text">{{ item.title }}</span>
                </v-list-item-title>
              </li>
            </ul>
            <v-divider v-if="openSubmenu" />
          </span>
        </ul>
      </div>
    </v-navigation-drawer>

    <v-app-bar app flat color="#ffffff" clipped-left class="overflow-hidden">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="$vuetify.breakpoint.smAndDown"></v-app-bar-nav-icon>
      <v-app-bar-title>
        <v-spacer />
        <img @click="redirect('mainDashboard')" src="../../../assets/chuna.png"
          :height="$vuetify.breakpoint.mdAndUp ? 50 : '40'" :class="$vuetify.breakpoint.mdAndUp
              ? 'justify-right'
              : ' justify-right ml-12 mt-2'
            " alt="CHUNA" />
        <v-spacer />
      </v-app-bar-title>

      <v-spacer />

      <v-btn dark v-if="!auth.check()" text :to="{ name: 'Login' }" class="mx-2">
        signIn
      </v-btn>
      <div class="text-center" v-if="auth.check() && $vuetify.breakpoint.mdAndUp">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar color="primary" size="56" v-bind="attrs" v-on="on">
              <v-img lazy-src="https://picsum.photos/id/11/10/6" max-height="150" max-width="250"
                src="https://picsum.photos/id/11/10/6"></v-img></v-avatar>

            <v-icon @click="changeImage()" class="ml-n3 mt-6" color="primary" dark>
              mdi-camera
            </v-icon>

            <!-- <v-icon
              x-large
              class="mr-2"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-account-circle
            </v-icon> -->
          </template>
          <v-list dense class="overflow-hidden" height="50">
            <v-list-item @click="redirect('changePin')">
              <v-list-item-icon>
                <v-icon small>mdi-blinds </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="all-div-text">
                Change Pin
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list dense class="overflow-hidden" height="50">
            <v-list-item @click="logout()">
              <v-list-item-icon>
                <v-icon small>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="all-div-text">
                Logout
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-file-input ref="image" id="uploader" accept="image/*" label="File input" v-if="showFile" />
      </div>
      <div class="text-center" v-if="auth.check() && !$vuetify.breakpoint.mdAndUp">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on">
              mdi-account-circle
            </v-icon>
          </template>
          <v-list v-if="$vuetify.breakpoint.mdAndUp" dense class="overflow-hidden" height="50">
            <v-list-item @click="redirect('changePin')">
              <v-list-item-icon>
                <v-icon>mdi-blinds </v-icon>
              </v-list-item-icon>
              <v-list-item-content> Change Pin </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense class="overflow-hidden" height="50">
            <v-list-item @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content> signout </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <loader />
    <app-footer />
  </v-app>
</template>

<script>
import { AuthService } from "@/modules/auth";
import AppFooter from "@/components/app-footer";

export default {
  name: "home",
  components: { AppFooter },
  data: () => ({
    drawer: null,
    mini: false,
    openSubmenu: false,
    selectedMenu: "",
    showFile: false,
    direction: "top",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    left: true,
    top: false,
    right: true,
    bottom: true,
    openDropdowns: [],
    show: false,

    transition: "slide-x-reverse-transition",
    children: [],
  }),

  beforeRouteEnter(to, from, next) {
    next(() => { });
  },

  computed: {
    auth() {
      return AuthService;
    },
    navLinks() {
      return [
        {
          to: "/dashboard",
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          disabled: false,
          children: [],
        },
        {
          to: "/deposit",
          icon: "mdi-currency-usd",
          title: "Deposit",
          disabled: false,
          children: [],
        },
        {
          to: "/send-money",
          title: "Withdrawals",
          icon: "mdi-cellphone",
          disabled: false,
          children: [],
        },
        {
          to: "/money-transfer",
          title: "Money Transfer",
          icon: "mdi-email-outline",
          disabled: false,
          children: [],
        },
        {
          to: "/standing-orders",
          title: "Standing Orders",
          icon: "mdi-email-outline",
          disabled: false,
          children: [],
        },
        {
          to: "",
          title: "Statements",
          icon: "mdi-file-document",
          disabled: false,
          children: [
            {
              to: "/mini-statement",
              title: "Mini Statement",
              icon: "",
              disabled: false,
              children: [],
            },
            {
              to: "/loan-statement",
              title: "Loan Statement",
              icon: "",
              disabled: false,
              children: [],
            },
            {
              to: "/detailed-statement",
              title: "Detailed Statement",
              icon: "",
              disabled: false,
              children: [],
            },

            {
              to: "/loans-guaranteed",
              title: "Loans Guaranteed",
              icon: "",
              disabled: false,
              children: [],
            },
            {
              to: "/repayment-schedule",
              title: "Repayment Schedule",
              icon: "",
              disabled: false,
              children: [],
            },

            {
              to: "/memberisloanguaranteed",
              title: "Member is loan guaranteed",
              icon: "",
              disabled: false,
              children: [],
            },
          ],
        },

        {
          to: "",
          title: "Apply Loan",
          icon: "mdi-key-variant",
          disabled: false,
          children: [
            {
              to: "/loan-application",
              title: "Apply Loan",
              icon: "mdi-key-variant",
              disabled: false,
              children: [],
            },
            {
              to: "/running-loans",
              title: "Running Loans",
              icon: "mdi-checkbox-marked-circle",
              disabled: false,
              children: [],
            },
            {
              to: "/loan-list",
              title: "Pending Loans",
              icon: "mdi-minus-circle",
              disabled: false,
              children: [],
            },
          ],
        },

        {
          to: "",
          title: "Guarantee Request",
          icon: "mdi-account",
          disabled: false,
          children: [
            {
              to: "/guarantee-request",
              title: "Guarantee Requests",
              icon: "mdi-star",
              disabled: false,
              children: [],
            },
            {
              to: "/approved-rejected",
              title: "Approved/Rejected Requests",
              icon: "mdi-star",
              disabled: false,
              children: [],
            },
          ],
        },
        {
          to: "",
          title: "Chuna Market",
          icon: "mdi-cart",
          disabled: false,
          children: [
          {
              to: "/create-bid",
              title: "Create",
              icon: "mdi-star",
              disabled: false,
              children: [],
            },
            {
              to: "/bid",
              title: "Bids",
              icon: "mdi-star",
              disabled: false,
              children: [],
            }
          ],
        },
      ];
    },
    profile() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
    currentRoute() {
      return this.$route.path;
    },
  },
  methods: {
    logout() {
      AuthService.logout();
    },
    redirect(val) {
      this.$router.push({ name: val });
    },

    openSubMenus(item, val) {
      this.selectedMenu = item.title;
      const navChildren = this.navLinks[val].children;
      this.children = [...navChildren];

      this.show = true;
    },

    close(val) {
      if (this.show && this.selectedMenu === val.title) {
        this.show = false;
      } else {
        this.selectedMenu = val.title;
        this.show = true;
      }
    },
    navigate(val) {
      this.$router.push({ path: val });
      this.navLinks.forEach((item) => {
        item.clicked = false; // Reset all items
      });
      const clickedItem = this.navLinks.find((item) => item.to === val);
      if (clickedItem) {
        clickedItem.clicked = true;
      }
    },
    isMenuActive(item) {
      if (item.to === this.currentRoute) {
        return true;
      }
      if (item.children && item.children.length) {
        return item.children.some(child => child.to === this.currentRoute);
      }
      return false;
    },
  },
  mounted() {
    if (this.currentRoute === '/dashboard/') {
      this.selectedMenu = this.navLinks[0].title;
    }
  },
  watch: {
    currentRoute: {
      immediate: true,
      handler() {
        const activeItem = this.navLinks.find((item) =>
          this.isMenuActive(item)
        );
        if (activeItem) {
          this.selectedMenu = activeItem.title;
          this.show = activeItem.children && activeItem.children.length > 0;
        }
      },
    },
  },
};
</script>

<style scoped>
.nav-link {
  font-family: DM Sans, sans-serif;
}

.nav-title {
  font-size: 14px;
  color: white;
}

.sub-nav-title {
  font-size: 14px;
  font-weight: 300;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.active-background:hover {
  color: #fcc019;

}

.active-sub-nav-title {
  font-size: 14px;
  font-weight: 300;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #fcc019;
}

.nav-selected {
  background-color: transparent !important;
  /* Remove background color */
  color: white !important;
  /* Set text color to white */
}

/* Additional styles for the selected item if needed */
.nav-selected:hover {
  background-color: transparent !important;
  /* Remove background color on hover */
}

.all-div-text {
  font-size: 14px;
  font-weight: 400;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.selected-row {
  background-color: #fcc019;
  cursor: pointer;
  border-radius: 4px;

}

.selected-row span,
.selected-row v-icon {
  color: black;
  font-size: 13px;
  font-weight: 500;
}

.active-background,
.active-background:hover {
  /* background-color: white; */
  cursor: pointer;
  /* Set the cursor style to pointer */

}

.sub-text {
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
}

.menu-list {
  list-style-type: none;
  /* Remove bullets */
  padding-left: 0;
  /* Remove default padding */
}

.sub-nav-title:hover span {
  color: #fcc019 !important;
  /* Changing the color of the span element on hover */
}

.hover-icon:hover {
  color: #fcc019 !important;
}
</style>
