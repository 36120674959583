<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    color="#26c6da"
    style="background-color: #dcdcdc"
    v-on:keydown.enter="login('firstTime')"
  >
    <v-row :class="$vuetify.breakpoint.mdAndUp ? 'mt-6' : 'mt-n5'">
      <v-col md="4" sm="12" offset-md="4">
        <v-sheet elevation="2" class="pa-10">
          <v-row>
            <v-col cols="12" md="12">
              <v-card flat>
                <v-alert
                  dense
                  v-if="$store.getters['Auth/alert'].status"
                  :type="
                    $store.getters['Auth/alert'].status === 'success'
                      ? 'success'
                      : 'error'
                  "
                  class="mb-10"
                >
                  {{ $store.getters["Auth/alert"].message }}
                </v-alert>
                <v-img
                  src="../../../assets/nhif-logo.png"
                  height="100"
                  contain
                  class="d-flex flex-row justify-center mt-n8"
                />
                <v-divider class="mt-n2" />

                <v-card-text class="text-justify mt-2 pb-0">
                  <div class="log-alert log-alert-info">
                    Self Care allows you to access choice/change of
                    facility,view contributions and more.
                  </div>
                  <!-- <v-form v-model="isValid" ref="loginForm" v-on:submit.prevent>
                      <v-text-field
                        dense
                        outlined
                        label=" National ID Number"
                        placeholder="Enter your ID Number"
                        v-model="formData.national_id"
                        ref="email"
                        :rules="rules.required"
                      />
                    </v-form> -->
                </v-card-text>

                <v-card-actions class="">
                  <v-row>
                    <v-col cols="12" md="6" sm="12">
                      <v-btn
                        class="btn-success btn-block black--text"
                        color="#63c2de"
                        :to="{ name: 'Login' }"
                      >
                        <span class="mt-2 text-underline"
                          >Sign In with NHIF
                        </span>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-btn
                        @click="redirectToTarget()"
                        class="btn-block black--text"
                        color="#ffc107"
                      >
                        <span class="no-underline">Sign in with e-citizen</span>
                      </v-btn>
                    </v-col>

                    <v-col cols="12" md="12" sm="12">
                      <v-btn
                        :to="{ name: 'Register' }"
                        class="btn-block black--text"
                        color="success"
                      >
                        <v-icon class="mt-1">mdi-account-plus</v-icon>
                        <span class="mt-1">Register</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <div class="alert text-muted">
                  <!-- Linda Mama Registration ?
                  <v-btn
                    text
                    class="btn-linda-mama pink--text"
                    :to="{ name: 'LindaMamaChoice' }"
                    >Register Here
                  </v-btn> -->
                </div>

                <div class="alert text-muted mt-n6">
                  For queries ,call NHIF Toll Free Line
                  <strong> 0800 720 601</strong>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="600" persistent>
      <v-card class="pa-7" tile elevation="5">
        <v-card-title class="d-flex flex-row justify-center">
          OTP CODE
        </v-card-title>

        <v-form v-model="isOtpValid" ref="otpForm">
          <v-alert
            outlined
            v-if="$store.getters['Auth/alert'].status"
            dense
            border="left"
            :type="
              $store.getters['Auth/alert'].status === 'success'
                ? 'success'
                : 'error'
            "
            class="mb-10"
          >
            {{ $store.getters["Auth/alert"].message }}
          </v-alert>

          <v-card-text>
            <v-otp-input
              label="OTP"
              length="4"
              ref="otp_code"
              :rules="rules.required"
              v-model="otpformData.otp"
              type="number"
              @input="verify()"
            ></v-otp-input>
          </v-card-text>
        </v-form>

        <v-card-actions>
          <v-btn color="red" @click="dialog = false" text> Cancel </v-btn>

          <v-spacer />

          <v-btn @click="login('resend')" color="primary"> Resend Otp </v-btn>
        </v-card-actions>
      </v-card>
      <Spinner />
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
import Spinner from "@/plugins/loader/views/Spinner";

export default {
  name: "landing",
  components: { Spinner },
  data: function () {
    return {
      isValid: false,
      isOtpValid: false,
      dialog: false,
      targetUrl:
        "https://accounts.ecitizen.go.ke/oauth/authorize?client_id=df345bec257011eeb9070050560101d6&redirect_uri=https://selfcare-ecitizen.nhif.or.ke&response_type=code",
      formData: {
        national_id: "",
        source: "WEB",
      },
      otpformData: {
        otp: "",
        id_number: "",
        source: "WEB",
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
    };
  },

  mounted() {
    EventBus.$on("otp-verification", () => {
      this.dialog = true;
    });
  },

  computed: {},
  methods: {
    login: function (val) {
      if (val === "firstTime") {
        if (!this.isValid) {
          this.$refs.loginForm.validate();
        } else {
          this.$store.dispatch("Auth/login", this.formData);
        }
      } else {
        if (val === "resend") {
          this.$store.dispatch("Auth/resendOTP", {
            id_number: this.formData.national_id,
            source: "WEB",
          });
        }
      }
    },
    redirectToTarget() {
      const targetUrl = this.targetUrl; // Replace with your desired URL
      window.location.href = targetUrl;
    },

    verify: function () {
      if (!this.isOtpValid) {
        this.$refs.otpForm.validate();
      } else {
        if (this.otpformData.otp.length === 4) {
          this.otpformData.id_number = this.formData.national_id;
          this.$store.dispatch("Auth/verifyOtp", this.otpformData);
        }
      }
    },
  },
};
</script>

<style scoped>
@import url("../styles.css");

.v-input__append-outer {
  margin-top: 8px;
}
</style>
