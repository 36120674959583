import dashboard from "@/modules/dashboard/views/dashboard";
import Auth from "@/modules/auth/middleware/Auth";
import destination from "@/modules/destination/components/destination";

export default [
  {
    path: "/destination",
    component: dashboard,

    children: [
      {
        path: "/",
        name: "destination",
        component: destination,
        meta: { middleware: [Auth]},
      },
    ],
  },
];

