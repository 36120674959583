<template>
  <v-container
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
  >
    <v-row align="center" justify="center">
      <v-col
        v-for="(bid, index) in test"
        :key="index"
        :cols="$vuetify.breakpoint.mdAndUp ? 12 : 6"
        md="3"
        sm="12"
        class="mt-3"
      >
        <v-card
          flat
          class=""
          color="#EDEADE"
          :height="$vuetify.breakpoint.mdAndUp ? 120 : 100"
        >
          <v-row>
            <v-card-text
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'mt-0 ml-2 black--text'
                  : 'text-caption font-weight-bold mt-0 ml-2 black--text'
              "
            >
              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'text-overline font-weight-bold mt-6 ml-2'
                    : 'text-caption font-weight-bold mt-0 ml-2'
                "
              >
                {{ bid.share_type || "N/A" }}</span
              >
              <p></p>

              <span
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'text-overline font-weight-bold mt-6 ml-2'
                    : 'text-caption font-weight-bold mt-0 ml-2'
                "
              >
                {{ bid.share_balance || "" }}</span
              >

              <v-card-actions class="">
                <v-spacer />
                <v-btn @click="placeBid()" small color="primary white--text">
                  <span>Bid</span>
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";
import payload from "@/mixin/payloadMethods";
export default {
  name: "BidPage",

  data: () => ({
    colors: [
      "green",
      "secondary",
      "yellow darken-4",
      "red lighten-2",
      "orange darken-1",
    ],
    cycle: true,
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    test: [
      {
        share_id: 1,
        customer_id: 1,
        share_balance: 25000,
        share_status: 0,
        share_type: "Normal Shares",
        member_number: "14749",
        account_number: "01-14749-01",
        bidder_member_number: "",
        createdon: "2024-07-06T10:18:50.000Z",
        createdby: 1,
        updatedon: null,
        updatedby: null,
      },
      {
        share_id: 2,
        customer_id: 1,
        share_balance: 25000,
        share_status: 0,
        share_type: "Normal Shares",
        member_number: "14749",
        account_number: "01-14749-01",
        bidder_member_number: "",
        createdon: "2024-07-06T10:19:43.000Z",
        createdby: 1,
        updatedon: null,
        updatedby: null,
      },
      {
        share_id: 3,
        customer_id: 1,
        share_balance: 25000,
        share_status: 0,
        share_type: "Normal Shares",
        member_number: "14749",
        account_number: "01-14749-01",
        bidder_member_number: "",
        createdon: "2024-07-06T10:21:55.000Z",
        createdby: 1,
        updatedon: null,
        updatedby: null,
      },
      {
        share_id: 4,
        customer_id: 1,
        share_balance: 25000,
        share_status: 0,
        share_type: "Normal Shares",
        member_number: "14749",
        account_number: "01-14749-01",
        bidder_member_number: "",
        createdon: "2024-07-06T10:35:22.000Z",
        createdby: 1,
        updatedon: null,
        updatedby: null,
      },
    ],
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        mti: "0200",
        f0: "1",
        f2: userInfo.phone_number,
        f3: "200000",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f60: "KES",
        f62: "KES",
        f68: "GET MARKET PLACE",
        f90: userInfo.customer_id,
        f123: "WEB",
      };
      v.$store.dispatch("Market/getBids", data);
    });
  },

  computed: {
    Images() {
      return this.$store.getters["Auth/authGetters"]("images");
    },
    clientInfo() {
      return userInfo;
    },
    bids() {
      return this.$store.getters["Market/marketGetters"]("bids");
    },
  },
  watch: {},

  methods: {
    redirect(val) {
      this.$router.push({ name: val });
    },
    placeBid() {

      const data = {
        mti: "0200",
        f0: "1",
        f2: "254705941419",
        f3: "220000",
        f4: "500",
        f7: "20240706130616",
        f11: "0616",
        f12: "130616",
        f13: "0107",
        f37: "AAA3AW5CVA",
        f60: "KES",
        f61: "1",
        f68: "PLACE BID",
        f90: 2,
        f92: "14749",
        f93: "01-14749-01",
        f123: "USSD",
      };
      this.$store.dispatch("Market/placeBids", data);
    },
  },
};
</script>

<style scoped>
@import url("../style.css");
</style>
