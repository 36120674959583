<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
  >
    <v-card v-if="!showBalances" class="mt-4">
      <v-skeleton-loader
        type="list-item-avatar-three-line, list-item-three-line, card-heading, list-item-three-line, list-item-three-line,list-item-three-line,list-item-three-line,list-item-three-line,list-item-three-line"
      ></v-skeleton-loader>
    </v-card>

    <v-card class="mx-auto" flat v-if="showBalances">
      <v-card-title class="">
        <span class="title-font-style">My Account</span>
      </v-card-title>
      <v-divider />

      <v-card-title class="">
        <span class="title-font-style">BOSA</span>
      </v-card-title>

      <v-card-text>
        <v-row v-if="balances.account_balances_bosa !== 0">
          <v-col cols="12" md="4" sm="12" v-for="(item, i) in BOSA" :key="i">
            <v-card class="mx-auto" outlined  style="border: 1px solid green">
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    <v-icon>mdi-alert-circle-outline</v-icon>
                    {{
                      item.account_name !== undefined
                        ? item.account_name
                        : "N/A"
                    }}
                  </div>
                  <v-list-item-title class="text-h5 mb-1">
                    <v-icon size="small" color="success" class="mr-1"
                      >mdi-brightness-1</v-icon
                    >
                    <span class="text-button mb-4">{{
                      item.bal_code ? item.bal_code : "N/A"
                    }}</span>
                  </v-list-item-title>

                  <v-row>
                    <v-col cols="4">
                      <v-list-item-title class="text-h5 mb-1">
                        <v-icon size="small" color="success" class="mr-1"
                          >mdi-brightness-1</v-icon
                        >
                        <span class="text-button mb-4">{{
                          item.balances ? item.balances : "N/A"
                        }}</span>
                      </v-list-item-title>
                    </v-col>
                    <v-col cols="8">
                      <div class="text-center">
                        <v-btn
                          class="ma-2"
                          small
                          outlined
                          color="indigo"
                          :to="{
                            name: 'account',
                            params: {
                              code: item.bal_code,
                            },
                          }"
                        >
                          view statement
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mx-auto mt-6" flat v-if="showBalances">
      <v-card-title class="">
        <span class="title-font-style">FOSA</span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    <v-icon>mdi-alert-circle-outline</v-icon
                    >{{
                      FOSA !== undefined
                        ? FOSA.account_name !== undefined
                          ? FOSA.account_name
                          : "Account Name"
                        : "Account Name"
                    }}
                  </div>
                  <v-list-item-title class="text-h5 mb-1">
                    <v-icon size="small" color="success"
                      >mdi-brightness-1</v-icon
                    >
                    <span class="text-button mb-4">
                      {{
                        FOSA !== undefined
                          ? FOSA.bal_code !== undefined
                            ? FOSA.bal_code
                            : "N/A"
                          : "N/A"
                      }}</span
                    >
                  </v-list-item-title>

                  <v-row>
                    <v-col cols="4">
                      <v-list-item-title class="text-h5 mb-1">
                        <v-icon size="small" color="success"
                          >mdi-brightness-1</v-icon
                        >
                        <span class="text-button mb-4">
                          {{
                            FOSA !== undefined
                              ? FOSA.balance !== undefined
                                ? FOSA.balance
                                : "N/A"
                              : "N/A"
                          }}</span
                        >
                      </v-list-item-title>
                    </v-col>
                    <v-col cols="8">
                      <div class="text-center">
                        <v-btn
                          v-if="FOSA !== undefined"
                          class="ma-2"
                          small
                          outlined
                          color="indigo"
                          :to="{
                            name: 'account',
                            params: {
                              code: FOSA.bal_code,
                            },
                          }"
                        >
                          view statement
                        </v-btn>
                        <v-btn
                          v-else
                          class="ma-2"
                          small
                          outlined
                          color="indigo"
                        >
                          view statement
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-center">
      <v-dialog persistent v-model="dialog" width="400" height="600">
        <v-card>
          <v-card-text class="justify-center service-title-text">
            Transcation Cost
          </v-card-text>
          <v-divider />

          <!-- Add transacionCost from Api -->
          <v-card-text class="justify-center service-text">
            You will be Charged KES {{ transacionCost.charges }} as transacion
            Cost
          </v-card-text>

          <v-card-actions>
            <v-btn
              small
              text
              class="white--text"
              color="red"
              :to="{ name: 'mainDashboard' }"
            >
              Back
            </v-btn>
            <v-spacer></v-spacer>

            <v-spacer />
            <v-btn small class="white--text" color="success" @click="Proceed()">
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import payload from "@/mixin/payloadMethods";
import userInfo from "@/modules/auth/clientInfo";

export default {
  name: "Balances",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const transData = {
        mti: "0200",
        f2: userInfo.phone_number,
        f3: "101018",
        f4: 0,
        f5: "ACCOUNTBALANCE",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f68: "ACCOUNT BALANCE TRANSACTION COST",
        f90: userInfo.customer_id,
        f123: "WEB",
        f92: userInfo.member_number,
        f93: userInfo.account_number,
      };
      v.$store.dispatch("Profile/getTrasactionCost", transData);
      
    });
  },
  data() {
    return {
      isLoading: true,
      dialog: true,
      showBalances: false,
    };
  },

  computed: {
    balances() {
      return this.$store.getters["Profile/profileGetters"]("balances");
    },
    BOSA() {
      return this.balances !== undefined
        ? this.balances.account_balances_bosa
        : [];
    },
    FOSA() {
      return this.balances.account_balances_fosa
        ? this.balances.account_balances_fosa[0]
        : {};
    },

    transacionCost() {
      return this.$store.getters["Profile/profileGetters"]("cost");
    },
  },
  methods: {
    Proceed() {
      this.showBalances = true;
      this.dialog = false;
      const data = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        mti: "0100",
        f11: payload.methods.getMinuteSecond(),
        f68: "Account Balance Enquiry",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: "330000",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };

      this.$store.dispatch("Profile/getBalances", data);
    },
  },
  watch: {},
  mounted() {},
};
</script>

<style scoped>
@import url("../style.css");
</style>
