import constants from "./marketConstants";
import call from "@/service/http";
import { EventBus } from "@/utils/eventBus";


export default {
  namespaced: true,
  state: {
    bids: [],

  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    marketGetters: (state) => (val) => state[val],
  },
  actions: {
    getBids: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.market, data)
        .then((res) => {
          if (res.data.f39 === "00") {
            commit("MUTATE", { state: "bids", value: res.data.f48 });
            commit("SET_LOADING", false, { root: true });
          } else{
            EventBus.$emit("showToast", {
                type: "error",
                message: res.data.f100,
                icon: "error",
              });
            commit("SET_LOADING", false, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
  

  },
};
