<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
    class=""
  >
    <!-- <v-card elevation="2" class="mt-6"> -->

    <v-row class="mt-4">
      <v-card class="mx-auto" width="800" flat>
        <v-card-title>
          <span class="title-font-style">Send Money to Mobile</span>
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-form v-model="isValid" ref="transferForm" v-on:submit.prevent>
            <span
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'text-overline new-main'
                  : 'mob-main-font-style'
              "
            >
              Withdraw From(FROM)</span
            >
            <v-autocomplete
              :items="newArray"
              v-model="formData.from"
              outlined
              dense
              :rules="rules.required"
              :item-value="(item) => item"
              :item-text="(item) => item.account_name"
              label="search your account"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-divider class="mt-n4" />

        <v-card-text class="text-center mt-n2">
          <v-card-text class="mt-n6">
            <v-row align="start" justify="start">
              <v-col cols="12"> </v-col>
              <v-btn-toggle v-model="toggle_exclusive" rounded>
                <v-btn
                  small
                  :class="paymentOption === 'mpesa' ? 'white--text' : ''"
                  :color="paymentOption === 'mpesa' ? 'success' : ''"
                  @click="choosePaymentOption('mpesa')"
                >
                  <v-icon
                    small
                    class="white--text"
                    left
                    v-if="paymentOption === 'mpesa'"
                  >
                    mdi-check
                  </v-icon>
                  M-PESA
                </v-btn>
                <v-btn
                  disabled
                  small
                  :class="paymentOption === 'airtel' ? 'white--text' : ''"
                  :color="paymentOption === 'airtel' ? 'success' : ''"
                  @click="choosePaymentOption('airtel')"
                >
                  <v-icon
                    small
                    class="white--text"
                    left
                    v-if="paymentOption === 'airtel'"
                  >
                    mdi-check
                  </v-icon>
                  AIRTEL
                </v-btn>
                <v-btn
                  disabled
                  small
                  :class="paymentOption === 'telkom' ? 'white--text' : ''"
                  :color="paymentOption === 'telkom' ? 'success' : ''"
                  @click="choosePaymentOption('telkom')"
                >
                  <v-icon
                    small
                    class="white--text"
                    left
                    v-if="paymentOption === 'telkom'"
                  >
                    mdi-check
                  </v-icon>
                  TELKOM
                </v-btn>
              </v-btn-toggle>
            </v-row>
          </v-card-text>
        </v-card-text>

        <v-card-text class="text-center">
          <v-card-text>
            <v-row align="start" justify="start">
              <v-col cols="12" class="mt-n3">
                <v-radio-group
                  :class="$vuetify.breakpoint.mdAndUp ? 'mt-4' : 'mt-n6'"
                  v-model="radioButton"
                  row
                >
                  <v-radio small label="SELF" value="SELF"></v-radio>
                  <!-- <v-radio small label="OTHER" value="OTHER"></v-radio> -->
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card-text>

        <v-card-text :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n6'">
          <v-form v-model="isTransfer" ref="repaymentForm" v-on:submit.prevent>
            <v-text-field
              dense
              outlined
              label="M-Pesa Number"
              placeholder="Enter Mpesa number"
              v-model="payformData.phone"
              :rules="rules.required"
              class="small-label"
            />
            <v-text-field
              dense
              outlined
              label="Amount to Pay"
              placeholder="Enter Amount to Pay"
              v-model="payformData.amount"
              :rules="rules.numberValidationRule"
              class="small-label"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="">
          <v-spacer />

          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            color="primary white--text"
            @click="checkCost()"
          >
            <span>Submit</span>
          </v-btn>
          <v-btn
            v-if="!$vuetify.breakpoint.mdAndUp"
            small
            @click="checkCost()"
            color="primary white--text"
          >
            <span>Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <div class="text-center">
      <v-dialog persistent v-model="dialog" width="400" height="600">
        <v-card>
          <v-card-text class="justify-center service-title-text">
            Transcation Cost
          </v-card-text>
          <v-divider />

          <!-- Add transacionCost from Api -->
          <v-card-text class="justify-center service-text">
            You will be Charged KES {{ transacionCost.charges }} as transacion
            Cost
          </v-card-text>

          <v-card-actions>
            <v-btn
              small
              text
              class="white--text"
              color="red"
              :to="{ name: 'mainDashboard' }"
            >
              Back
            </v-btn>
            <v-spacer></v-spacer>

            <v-spacer />
            <v-btn small class="white--text" color="success" @click="sendOtp()">
              Proceed
            </v-btn>
          </v-card-actions>
          <Spinner />
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="otpDialog" width="600" persistent>
      <v-card height="250">
        <v-card-title class="text-button text-center">
          <v-spacer />
          Wait for OTP code
          <v-spacer />
          <v-icon @click="closeOtpDialog()">mdi-close-circle</v-icon>
        </v-card-title>

        <v-card-text>
          <v-alert
            outlined
            v-if="$store.getters['Auth/alert'].status"
            dense
            border="left"
            :type="
              $store.getters['Auth/alert'].status === 'success'
                ? 'success'
                : 'error'
            "
            class="mt-3"
          >
            {{ $store.getters["Auth/alert"].message }}
          </v-alert>

          <v-otp-input
            length="4"
            v-model="otp_code"
            @finish="verifyOtp"
            class="mt-6"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            small
            color="success"
            class="white--text"
            :loading="$store.getters['loading']"
            @click="sendOtp"
          >
            Resend OTP
          </v-btn>

          <v-spacer />

          <v-btn
            small
            :disabled="otp_code.length < 4"
            :loading="$store.getters['loading']"
            @click="verifyOtp"
            color="primary"
          >
            Verify OTP
          </v-btn>
        </v-card-actions>
      </v-card>
      <Spinner />
    </v-dialog>


    
  </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";

import payload from "@/mixin/payloadMethods";
import { EventBus } from "@/utils/eventBus";
import Spinner from "@/plugins/loader/views/Spinner";
export default {
  name: "mobiletransfer",
  components: { Spinner },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        mti: "0200",
        f11: payload.methods.getMinuteSecond(),
        f68: "GET SOURCE ACCOUNT FOR WEB",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: "431000",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };
      v.$store.dispatch("source/getSourceAccs", data);

      const setdata = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        mti: "0100",
        f11: payload.methods.getMinuteSecond(),
        f68: "GET DESTINATION ACCOUNT FOR WEB",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: "432000",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };
      v.$store.dispatch("destination/getDestinationAccounts", setdata);
      const transData = {
        mti: "0200",
        f2: userInfo.phone_number,
        f3: "101018",
        f4: 0,
        f5: "M-pesa",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f68: "M-PESA",
        f90: userInfo.customer_id,
        f123: "WEB",
        f92: userInfo.member_number,
        f93: userInfo.account_number,
      };
      v.$store.dispatch("Profile/getTrasactionCost", transData);
    });
  },
  data() {
    return {
      newButton: false,
      otp_code: "",
      otpDialog: false,
      newArray: [],
      sucessDialog: false,
      errorDialog: true,
      isValid: false,
      dialog: false,
      paymentOption: "mpesa",
      radioButton: "",
      isTransfer: false,
      toggle_exclusive: undefined,
      errorText: "",
      destinations: [],
      formData: {
        to: "",
        from: "",
        amount: "",
      },
      payformData: {
        phone: "",
        amount: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],
        numberValidationRule: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
          (value) =>
            (value && parseInt(value) >= 50) || "Value must not be below 50",
        ],
      },
    };
  },

  computed: {
    bosa() {
      return this.$store.getters["destination/destinationGetters"](
        "destinations"
      ).bosa_accounts;
    },

    fosa() {
      return this.$store.getters["destination/destinationGetters"](
        "destinations"
      ).fosa_accounts;
    },

    sourceAccs() {
      return this.$store.getters["source/sourceGetters"]("sourceAccs");
    },
    transacionCost() {
      return this.$store.getters["Profile/profileGetters"]("cost");
    },
  },
  methods: {
    choosePaymentOption(val) {
      this.paymentOption = val;
    },

    checkCost() {
      if (!this.isValid) {
        this.$refs.transferForm.validate();
      } else {
        if (!this.isTransfer) {
          this.$refs.repaymentForm.validate();
        } else {
          if (this.payformData.amount === "0") {
            this.$toast.error("Inavlid Amount");
          } else {
            if (Number(this.payformData.amount) > this.fosa[0].balance) {
              const balance = this.fosa[0].balance;
              this.$toast.error(
                `You have insufficient balance, Balance: KES ${balance}`
              );
            } else {
              this.dialog = true;
            }
          }
        }
      }
    },
    sendMoney() {
      const newPhone = this.payformData.phone.replace(/^0|254/g, "");
      const NP = "254" + newPhone;
      const data = {
        mti: "0200",
        f0: "1",
        f2: userInfo.phone_number,
        f3: "440000",
        f4: this.payformData.amount,
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f60: "KES",
        f65: this.formData.from.account_no,
        f68: "Send to Mpesa",
        f90: userInfo.customer_id,
        f103: NP,
        f123: "WEB",
        f92: userInfo.member_number,
        f101: false,
      };

      this.$store.dispatch("destination/sendtoMobile", data);
    },

    verifyOtp: function () {
      const data = {
        mti: "0200",
        f0: "0",
        f2: userInfo.phone_number,
        f3: "101016",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f60: "KES",
        f68: "Validate OTP",
        f90: userInfo.customer_id,
        f123: "WEB",
        f56: this.otp_code,
        f93: userInfo.account_number,
      };

      this.$store.dispatch("loans/verifyOtp", data);
    },
    sendOtp: function () {
      const data = {
        mti: "0200",
        f0: "0",
        f2: userInfo.phone_number,
        f3: "101012",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f60: "KES",
        f68: "Request OTP",
        f90: userInfo.customer_id,
        f123: "WEB",
        f56: userInfo.phone_number,
        f92: userInfo.phone_number,
        f93: userInfo.account_number,
      };

      this.$store.dispatch("loans/validateMoneyOtp", data);
    },

    // Proceed() {
    // this.dialog = false;
    // this.sendOtp();

    // this.sendMoney();
    // },
    closeOtpDialog() {
      this.otpDialog = false;
    },
  },
  watch: {
    fosa: {
      handler: function () {
        if (this.fosa.length > 0) {
          this.newArray = [...this.fosa];
          this.formData.from = this.fosa[0];
        }
      },
    },
    radioButton: {
      handler: function () {
        if (this.radioButton === "SELF") {
          this.payformData.phone = userInfo.phone_number;
        } else {
          if (this.radioButton === "OTHER") {
            this.payformData.phone = "";
          }
        }
      },
    },
  },
  mounted() {
    this.radioButton = "SELF";
    // EventBus.$on("successSendMoney", () => {
    //   this.$refs.repaymentForm.reset();
    //   this.radioButton = "";
    //   this.otpDialog = false;
    // });

    EventBus.$on("otp-money-verification", () => {
      this.otpDialog = true;
      this.dialog = false;
    });
    EventBus.$on("otp-money-close", () => {
      this.otpDialog = false;
    });
    EventBus.$on("proceed", () => {
      this.sendMoney();
      this.dialog = false;
    });
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
