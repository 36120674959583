<template>
  <v-footer v-if="$vuetify.breakpoint.mdAndUp" app :inset="true" elevation="0" class="overline footer-content"
    color="white" style="align-items:center ">
    <v-row><v-col style="font-size: 0.625rem;">
        CHUNA
      </v-col><v-col cols="9" class="text-center mx-auto"
        style="font-size: 0.625rem; display: flex; flex-direction: row;">
        Copyright © 2023 &nbsp;

        <a href="https://www.internetbanking.chunasacco.co.ke/LoanApplicationNew.aspx" class="ChunaWebsite"
          style="color: rgb(82, 82, 183)">CHUNA DT SACCO</a>
        &nbsp; Contact Us Through &nbsp;
        <a href="https://chunasacco.co.ke/" style="color: rgb(82, 82, 183)">ChunaWebsite</a>
        &nbsp; Email Us:@
        <div @mailto:click="openemailclient('chunasacco@uonbi.ac.ke')" style="color: rgb(82, 82, 183)">
          mailto:chunasacco@uonbi.ac.ke
        </div>
      </v-col></v-row>

  </v-footer>
</template>

<script>
export default {
  name: "app-footer",
  props: {
    opacity: {
      type: String,
      default: "1",
    },
  },

  computed: {},
  methods: {
    openEmailClient(toEmail) {
      const emailLink = `mailto:${toEmail}`;
      window.location.href = emailLink;
    },
  },
};
</script>

<style>
.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
