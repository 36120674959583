<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndUp ? true : false" style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    " class="">
    <!-- <v-card elevation="2" class="mt-6"> -->
    <v-tabs v-model="tab" bg-color="indigo-darken-2" fixed-tabs>
      <v-tab> MY ACCOUNT </v-tab>
      <v-tab> OTHER ACCOUNTS </v-tab>
    </v-tabs>

    <v-row class="mt-4" v-if="tab === 0">
      <v-card class="mx-auto" width="800" flat>
        <v-card-title>
          <span class="title-font-style">Money Transfer</span>
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-form v-model="isValid" ref="transferForm" v-on:submit.prevent>
            <span :class="$vuetify.breakpoint.mdAndUp
                ? 'text-overline new-main'
                : 'mob-main-font-style'
              ">
              Source(FROM)</span>
            <v-autocomplete :items="sourceAccs.length !== 0 ? sourceAccs : []" v-model="formData.from" outlined dense
              :rules="rules.required" :item-value="(item) => item" :item-text="(item) => item.account_name"
              label="search your account" class="small-label"></v-autocomplete>

            <span :class="$vuetify.breakpoint.mdAndUp
                ? 'text-overline new-main'
                : 'mob-main-font-style'
              ">
              Destination (TO)</span>

            <v-autocomplete :items="destinations.length !== 0 ? destinations : []" v-model="formData.to" outlined dense
              :rules="rules.required" :item-value="(item) => item" :item-text="(item) => item.account_name"
              label="search your account" class="small-label"></v-autocomplete>

            <span :class="$vuetify.breakpoint.mdAndUp
                ? 'text-overline new-main'
                : 'mob-main-font-style'
              ">
              Amount</span>

            <v-text-field label="Amount" v-model="formData.amount" outlined dense :rules="rules.required" type="number"
              class="small-label"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="">
          <v-spacer />

          <v-btn v-if="$vuetify.breakpoint.mdAndUp" class="white--text" color="#25ad64" @click="checkTransCost()">
            <span>Submit</span>
          </v-btn>
          <v-btn v-if="!$vuetify.breakpoint.mdAndUp" small @click="checkTransCost()" class="white--text"
            color="#25ad64">
            <span>Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-row class="mt-4" v-if="tab === 1">
      <v-card class="mx-auto" width="800" flat>
        <v-card-title>
          <span class="title-font-style">Money Transfer</span>
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-form v-model="isOtherValid" ref="transferForm" v-on:submit.prevent>
            <span :class="$vuetify.breakpoint.mdAndUp
                ? 'text-overline new-main'
                : 'mob-main-font-style'
              ">
              Source(FROM)</span>
            <v-autocomplete :items="sourceAccs.length !== 0 ? sourceAccs : []" v-model="otherFormData.from" outlined
              dense :rules="otherRules.required" :item-value="(item) => item" :item-text="(item) => item.account_name"
              label="search your account" class="small-label"></v-autocomplete>
            <span :class="$vuetify.breakpoint.mdAndUp
                ? 'text-overline new-main'
                : 'mob-main-font-style'
              ">
              Member to be transferred MEMBER NUMBER</span>

            <v-text-field dense outlined label="Member Number" placeholder="" v-model="otherFormData.member_number"
              ref="idNo" :rules="otherRules.required" class="small-label" />

            <v-card-actions class="mt-n5">
              <v-spacer />
              <v-btn @click="ValidateMemNo()" small class="white--text" color="black">
                <span>Validate</span>
              </v-btn></v-card-actions>
            <!-- 
            <span
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'text-overline new-main'
                  : 'mob-main-font-style'
              "
            >
              Member to be transferred ID NUMBER</span
            >

            <v-text-field
              dense
              outlined
              label="ID Number"
              placeholder=""
              v-model="otherFormData.id_number"
              ref="memNo"
              :rules="otherRules.required"
              @change="getNames(otherFormData.member_number)"
            /> -->

            <span :class="$vuetify.breakpoint.mdAndUp
                ? 'text-overline new-main'
                : 'mob-main-font-style'
              ">
              Destination (TO)</span>

            <v-text-field :disabled="!showFields" label="Destination" v-model="otherFormData.to" outlined dense
              :rules="otherRules.required" class="small-label"></v-text-field>

            <span :class="$vuetify.breakpoint.mdAndUp
                ? 'text-overline new-main'
                : 'mob-main-font-style'
              ">
              Amount</span>

            <v-text-field :disabled="!showFields" label="Amount" v-model="otherFormData.amount" outlined dense
              :rules="otherRules.required" type="number" class="small-label"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="">
          <v-spacer />

          <v-btn v-if="$vuetify.breakpoint.mdAndUp" class="white--text" color="#25ad64" @click="checkTransOtherCost()">
            <span>Submit</span>
          </v-btn>
          <v-btn v-if="!$vuetify.breakpoint.mdAndUp" small @click="checkTransOtherCost()" class="white--text"
            color="#25ad64">
            <span>Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-dialog v-model="loader" persistent width="500">
      <v-card color="primary" dark>
        <v-card-text>
          Please Wait as We Retrieve the Details
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="userDialog" max-width="600" persistent>
      <v-card>
        <v-card-title class="text-h6">MEMBER DETAILS </v-card-title>
        <v-card-title class="text-h6">Name:
          <span class="red--text ml-4">{{ person ? person.name : "N/A" }}</span>
        </v-card-title>
        <v-card-title class="text-h6">Account No:<span class="red--text ml-4">{{
          person ? person.account_no : "N/A"
        }}</span>
        </v-card-title>
        <v-card-title class="text-h6">Member No:<span class="red--text ml-4">{{
          person ? person.member_no : "N/A"
        }}</span>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" @click="userDialog = false"> ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <div class="text-center">
      <v-dialog persistent v-model="transferDialog" width="400" height="600">
        <v-card>
          <v-card-text class="justify-center service-title-text">
            Transcation Cost
          </v-card-text>
          <v-divider />

          <!-- Add transacionCost from Api -->
          <v-card-text class="justify-center service-text">
            You will be Charged KES {{ transactionCost ? transactionCost.charges : 0 }} as transacion
            Cost
          </v-card-text>

          <v-card-actions>
            <v-btn small text class="white--text" color="red" @click="transferDialog = false">
              Back
            </v-btn>
            <v-spacer></v-spacer>

            <v-spacer />
            <v-btn small class="white--text" color="success" @click="sendOtp('original')">
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog persistent v-model="transferOtherDialog" width="400" height="600">
        <v-card>
          <v-card-text class="justify-center service-title-text">
            Transcation Cost
          </v-card-text>
          <v-divider />

          <!-- Add transacionCost from Api -->
          <v-card-text class="justify-center service-text">
            You will be Charged KES {{ transactionCost ? transactionCost.charges : 0 }} as transacion
            Cost
          </v-card-text>

          <v-card-actions>
            <v-btn small text class="white--text" color="red" @click="transferOtherDialog = false">
              Back
            </v-btn>
            <v-spacer></v-spacer>

            <v-spacer />
            <v-btn small class="white--text" color="success" @click="sendOtp('other')">
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="otpDialog" width="600" persistent>
      <v-card height="250">
        <v-card-title class="text-button text-center">
          <v-spacer />
          Wait for OTP code
          <v-spacer />
          <v-icon @click="closeOtpDialog()">mdi-close-circle</v-icon>
        </v-card-title>

        <v-card-text>
          <v-alert outlined v-if="$store.getters['Auth/alert'].status" dense border="left" :type="$store.getters['Auth/alert'].status === 'success'
              ? 'success'
              : 'error'
            " class="mt-3">
            {{ $store.getters["Auth/alert"].message }}
          </v-alert>

          <v-otp-input length="4" v-model="otp_code" @finish="verifyOtp" class="mt-6" />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn small color="success" class="white--text" :loading="$store.getters['loading']"
            @click="sendOtp('resend')">
            Resend OTP
          </v-btn>

          <v-spacer />

          <v-btn small :disabled="otp_code.length < 4" :loading="$store.getters['loading']" @click="verifyOtp"
            color="primary">
            Verify OTP
          </v-btn>
        </v-card-actions>
      </v-card>
      <Spinner />
    </v-dialog>
  </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";
import Spinner from "@/plugins/loader/views/Spinner";
import payload from "@/mixin/payloadMethods";
import { EventBus } from "@/utils/eventBus";
export default {
  name: "transfer",
  components: { Spinner },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        mti: "0200",
        f11: payload.methods.getMinuteSecond(),
        f68: "GET SOURCE ACCOUNT FOR WEB",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: "431000",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };
      v.$store.dispatch("source/getSourceAccs", data);

      const setdata = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        mti: "0100",
        f11: payload.methods.getMinuteSecond(),
        f68: "GET DESTINATION ACCOUNT FOR WEB",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: "432000",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };
      v.$store.dispatch("destination/getDestinationAccounts", setdata);

      const transData = {
        mti: "0200",
        f2: userInfo.phone_number,
        f3: "101018",
        f4: 0,
        f5: "FUNDSTRANSFER",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f68: "FUNDSTRANSFER",
        f90: userInfo.customer_id,
        f123: "WEB",
        f92: userInfo.member_number,
        f93: userInfo.account_number,
      };
      v.$store.dispatch("Profile/getTrasactionCost", transData);
    });
  },
  data() {
    return {
      otpDialog: false,
      otp_code: "",
      showFields: false,
      tab: null,
      userDialog: false,
      isValid: false,
      isOtherValid: false,
      sucessDialog: false,
      errorDialog: false,
      transferDialog: false,
      transferOtherDialog: false,
      destinations: [],
      otherDestinations: [],
      loader: false,
      otpChoice: '',
      formData: {
        to: "",
        from: "",
        amount: "",
      },
      otherFormData: {
        to: "",
        from: "",
        amount: "",
        member_number: "",
        id_no: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
      otherRules: {
        required: [(value) => !!value || "Required."],
      },
    };
  },

  computed: {
    bosa() {
      return this.$store.getters["destination/destinationGetters"](
        "destinations"
      ).bosa_accounts;
    },

    fosa() {
      return this.$store.getters["destination/destinationGetters"](
        "destinations"
      ).fosa_accounts;
    },

    sourceAccs() {
      return this.$store.getters["source/sourceGetters"]("sourceAccs");
    },
    transactionCost() {
      return this.$store.getters["Profile/profileGetters"]("cost");
    },
    person() {
      return this.$store.getters["loans/loansGetters"]("sendToPerson");
    },
  },
  methods: {
    closeOtpDialog() {
      this.otpDialog = false;
    },
    verifyOtp: function () {
      const data = {
        mti: "0200",
        f0: "0",
        f2: userInfo.phone_number,
        f3: "101016",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f60: "KES",
        f68: "Validate OTP",
        f90: userInfo.customer_id,
        f123: "WEB",
        f56: this.otp_code,
        f93: userInfo.account_number,
      };

      this.$store.dispatch("loans/verifyOtp", data);
    },
    sendOtp: function (val) {
      this.otpChoice = val;
      const data = {
        mti: "0200",
        f0: "0",
        f2: userInfo.phone_number,
        f3: "101012",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f60: "KES",
        f68: "Request OTP",
        f90: userInfo.customer_id,
        f123: "WEB",
        f56: userInfo.phone_number,
        f92: userInfo.phone_number,
        f93: userInfo.account_number,
      };

      this.$store.dispatch("loans/validateMoneyOtp", data);
    },
    objectExistsInArrays(targetObject, array1, array2) {
      if (array1.some((obj) => obj === targetObject)) {
        return "BOSA";
      } else {
        if (array2.some((obj) => obj === targetObject)) {
          return "FOSA";
        }
      }
    },
    checkTransCost() {
      if (!this.isValid) {
        this.$refs.transferForm.validate();
      } else {
        if (this.formData.to.account_no === this.formData.from.account_no) {
          this.$toast.error("Cannot complete transaction to the same Account");
        } else {
          if (this.formData.amount <= "0") {
            this.$toast.error("Inavlid Amount");
          } else {
            if (Number(this.formData.amount) > userInfo.transaction_limit) {
              this.$toast.error("Amount Exceeds Limit");
            } else {
              this.transferDialog = true;
            }
          }
        }
      }
    },
    checkTransOtherCost() {
      if (!this.isOtherValid) {
        this.$refs.transferForm.validate();
      } else {
        if (
          this.otherFormData.to.account_no ===
          this.otherFormData.from.account_no
        ) {
          this.$toast.error("Cannot complete transaction to the same Account");
        } else {
          if (this.otherFormData.amount <= "0") {
            this.$toast.error("Inavlid Amount");
          } else {
            if (
              Number(this.otherFormData.amount) > userInfo.transaction_limit
            ) {
              this.$toast.error(" Amount Exceeds Limit");
            } else {
              this.transferOtherDialog = true;
            }
          }
        }
      }
    },
    Proceed() {
      this.sendMoney();
      this.$refs.transferForm.reset();

    },
    ProceedOther() {
      this.sendOtherMoney();
      this.transferOtherDialog = false;
      this.$refs.transferForm.reset();
    },
    getNames() {
      this.loader = true;
      const data = {
        mti: "0200",
        f2: userInfo.phone_number,
        f3: "800800",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f68: "Validate Member Number",
        f90: userInfo.customer_id,
        f92: this.otherFormData.member_number,
        f56: this.otherFormData.member_number,
        f123: "WEB",
      };

      this.$store.dispatch("loans/validateChange", data);
    },
    ValidateMemNo() {
      this.showFields = true;
      this.getNames(this.otherFormData.member_number);
    },

    sendMoney() {
      const data = {
        mti: "0200",
        f2: userInfo.phone_number,
        f3: "430000",
        f4: this.formData.amount,
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f60: "KES",
        f68: "FUNDS TRANSFER",
        f90: userInfo.customer_id,
        f92: userInfo.member_number,
        f100: "FOSA",
        f102: this.formData.from.account_no, //source account
        f103: this.formData.to.account_no, //destination account
        f123: "WEB",
      };

      this.$store.dispatch("destination/sendMoney", data);
    },
    sendOtherMoney() {
      const data = {
        mti: "0200",
        f2: userInfo.phone_number,
        f3: "430000",
        f4: this.otherFormData.amount,
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f60: "KES",
        f68: "FUNDS TRANSFER",
        f90: userInfo.customer_id,
        f92: userInfo.member_number,
        f100: "FOSA",
        f102: this.otherFormData.from.account_no, //source account
        f103: this.person.account_no, //destination account
        f123: "WEB",
      };

      this.$store.dispatch("destination/sendMoney", data);
    },
  },

  watch: {
    bosa: {
      handler: function () {
        if (this.bosa.length > 0) {
          this.destinations = [...this.bosa, ...this.fosa];
        }
      },
    },

    person: {
      handler: function () {
        if (this.person) {
          this.userDialog = true;
          this.otherFormData.to = this.person.name;
          this.loader = false;
        }
      },
    },
  },
  mounted() {
    EventBus.$on("successTransfer", () => {
      this.$refs.transferForm.reset();
    });
    EventBus.$on("closeDialog", () => {
      // this.$refs.payeeamount.reset();
      this.loader = false;
    });
    EventBus.$on("otp-money-verification", () => {
      this.otpDialog = true;
      this.transferDialog = false;
      this.transferOtherDialog = false;
    });

    EventBus.$on("otp-money-close", () => {
      this.otpDialog = false;

    });
    EventBus.$on("proceed", () => {
      if (this.otpChoice === 'original') {
        this.Proceed();
      } else {
        if (this.otpChoice === 'other') {
          this.ProceedOther()
        }

      }
    });

    
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
