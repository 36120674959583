import dashboard from "@/modules/dashboard/views/dashboard";
import mainDashboard from "@/modules/dashboard/components/mainDashboard";
import Auth from "@/modules/auth/middleware/Auth";
import changePin from "@/modules/dashboard/components/changePin";
import deposit from "@/modules/dashboard/components/deposit"

export default [
  {
    path: "/dashboard",
    component: dashboard,

    children: [
      {
        path: "/",
        name: "mainDashboard",
        component: mainDashboard,
        meta: { middleware: [Auth]},
      },
      {
        path: "/change-pin",
        name: "changePin",
        component: changePin,
        meta: { middleware: [Auth]},
      },
      {
        path: "/deposit",
        name: "deposit",
        component: deposit,
        meta: { middleware: [Auth]},
      },
    ],
  },
];
