import routes from "./sourceRoutes";
import store from "./sourceStore";

export default {
  install(Vue, options) {
    options.router.addRoute("/", ...routes);
    options.store.registerModule("source", store);
  },
};

