<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>

    <spinner />

    <app-alert />

    <app-footer />

    <confirmation />
  </v-app>
</template>

<script>
import Spinner from "@/plugins/loader/views/Spinner";
import AuthService from "@/modules/auth/authService";
import AppFooter from "@/components/app-footer";

export default {
  name: "authLayout",
  components: { AppFooter, Spinner },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AuthService.check()) {
        v.$router.replace({
          name: "mainDashboard",
        });
      }
    });
  },

  computed: {
    auth() {
      return AuthService;
    },
  },

  watch: {
    "$route.name": {
      handler: function () {
        this.$store.commit("Auth/SET_ALERT", null);
      },
      immediate: true,
    },
  },
  directives: {
    'disable-right-click': {
      inserted(el) {
        // Disable contextmenu event
        el.addEventListener('contextmenu', disableEvent)
        // Disable auxiliaryclick event
        el.addEventListener('auxiliaryclick', disableEvent)
      },
      unbind(el) {
        // Enable contextmenu event
        el.removeEventListener('contextmenu', disableEvent)
        // Enable auxiliaryclick event
        el.removeEventListener('auxiliaryclick', disableEvent)
      }
    }
    
  },
  mounted() {
    // Add an event listener for the keydown event on the window
    window.addEventListener('keydown', this.disableControlShiftC);
  },
  beforeUnmount() {
    // Remove the event listener when the component is unmounted
    window.removeEventListener('keydown', this.disableControlShiftC);
  },
  methods: {
    disableControlShiftC(event) {
      // Check if the Control, Shift, and 'C' keys are pressed simultaneously
      if (event.ctrlKey && event.shiftKey && event.key === 'C') {
        // Prevent the default behavior of the keydown event
        event.preventDefault();

        // Optionally, you can also display a custom message or take other actions here
        console.log('Control+Shift+C shortcut is disabled.');
      }
    }
  }
  

  
};



function disableEvent(event) {
  event.preventDefault()
}
</script>

<style scoped></style>
