import routes from "./loyaltyRoutes";
import store from "./loyaltyStore";

export default {
  install(Vue, options) {
    options.router.addRoute("/", ...routes);
    options.store.registerModule("loyalty", store);
  },
};

