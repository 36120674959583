<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndUp ? true : false" style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    ">
    <v-card class="mx-auto mt-3" flat>
      <v-card-title>
        <!-- <v-btn color="primary" text :to="{ name: 'balances' }">
            <v-icon>mdi-arrow-left</v-icon>
            <span>Back</span>
          </v-btn> -->
      </v-card-title>
      <v-card-title>
        <span class="title-font-style">Pending Loans</span>
      </v-card-title>

      <v-data-table :headers="headers" :items="loans.length > 0 || Array.isArray(loans) ? loans : []"
        :items-per-page="11" class="elevation-0 test-table" :mobile-breakpoint="0">
        <template v-slot:[`item.loan_installment`]="{ item }">
          <span :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'">
            <span> KSH {{ item.loan_installment }}</span></span>
        </template>

        <template v-slot:[`item.loan_balance`]="{ item }">
          <span :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'">
            <span> KSH {{ item.loan_balance }}</span></span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">

          <v-btn depressed small color="success" @click="repayLoan(item, 'mpesa')" class="ml-2">
            <span :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'">View to add guarantor</span>
          </v-btn>
          <!-- 
          <v-btn
            depressed
            small
            color="primary"
            @click="repayLoan(item, 'FOSA')"
            class="ma-2"
          >
            <span
              :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
              >Submit</span
            >
          </v-btn>
          <v-btn
            depressed
            small
            color="red"
            @click="repayLoan(item, 'FOSA')"
            class="ma-2 white--text"
          >
            <span
              :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
              >Download Form
            </span>
          </v-btn> -->
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="800px" height="">
      <v-card flat class="mx-auto">
        <v-card-title class="">
          <!-- <v-spacer/> -->
          <span class="text-button deep-purple--text font-weight-bold mt-0">Loan Details
          </span>
          <v-spacer />
          <v-icon @click="closeDialog()" color="red">mdi-close</v-icon>
        </v-card-title>

        <v-card-title class="mt-n4">
          <v-spacer />

          <span class="text-subtitle-2 black--text mt-n3">More Information :
          </span>
          <v-spacer />
        </v-card-title>
        <v-card outlined class="mx-4 mt-3" align="start" style="border: 1px solid green">
          <v-card-title>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.mdAndUp ? '' : 12">
                <div class="d-flex">
                  <span class="main-title-text">
                    <v-icon color="success" size="x-small">mdi-checkbox-blank-circle</v-icon>
                    Description:
                  </span>
                  <span class="text-overline green--text">{{ " " }}
                    {{
                      loanSelected ? " " + loanSelected.loan_type : "N/A"
                    }}</span>
                </div>
                <!-- <div class="d-flex">
                  <span class="main-title-text">
                    <v-icon color="success" size="x-small"
                      >mdi-checkbox-blank-circle</v-icon
                    >
                    Application Date:
                  </span>
                  <span class="text-overline green--text">
                    {{
                      loanSelected ? loanSelected.loan_application_date : "N/A"
                    }}</span
                  >
                </div> -->
                <div class="d-flex">
                  <span class="main-title-text">
                    <v-icon color="success" size="x-small">mdi-checkbox-blank-circle</v-icon>
                    Installment:
                  </span>
                  <span class="text-overline green--text">
                  
                    {{ loanSelected ? loanSelected.installement : "N/A" }}</span>
                </div>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mdAndUp ? '' : 12" :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n6 mb-3'">
                <div class="d-flex">
                  <span class="main-title-text">
                    <v-icon color="success" size="x-small">mdi-checkbox-blank-circle</v-icon>
                    Requested Amount:
                  </span>
                  <span class="text-overline green--text">
                    KSH
                    {{
                      loanSelected ? loanSelected.requested_Amount : "N/A"
                    }}</span>
                </div>
                <!-- <div class="d-flex">
                  <span class="main-title-text">
                    <v-icon color="success" size="x-small"
                      >mdi-checkbox-blank-circle</v-icon
                    >
                    Issue Date:
                  </span>
                  <span class="text-overline green--text">
                    {{
                      loanSelected ? loanSelected.loan_issue_date : "N/A"
                    }}</span
                  >
                </div> -->
              </v-col></v-row>
          </v-card-title>

          <v-card-title class="mt-n4">
            <v-spacer />

            <span class="text-subtitle-2 black--text mt-n3">Guarantors : </span>
            <v-spacer />
            <v-btn v-if="checkGuarantors" small depressed color="primary" :to="{
              name: 'addGuarantor',
              params: {
                code: loanSelected.application_no,
                type: encodeString(loanSelected.loan_type),
              },
            }">
              <span>Add Guarantor</span>
            </v-btn>
            <v-btn color="red" small class="ml-3 white--text"  :to="{
              name: 'uploadDocuments',
              params: {
                code: loanSelected.application_no
              },
            }">
              <span>Upload Document</span>
            </v-btn>
          </v-card-title>
          <v-card-title v-if="!checkGuarantors">
            <v-spacer />
            <v-btn small depressed color="primary" :to="{
              name: 'addGuarantor',
              params: {
                code: loanSelected.application_no,
                type: encodeString(loanSelected.loan_type),
              },
            }">
              <span>Add Guarantor</span>
            </v-btn>
            <v-spacer />
          </v-card-title>

          <v-simple-table v-if="checkGuarantors">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Application No</th>
                  <th class="text-left">Member No</th>
                  <th class="text-left">Member ID</th>
                  <th class="text-left">Member Name</th>

                  <th class="text-left">Amount</th>
                  <th class="text-left">Approval Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in loanSelected.guarantors" :key="i">
                  <td>{{ item.application_no }}</td>
                  <td>{{ item.member_no }}</td>

                  <td>{{ item.id_no }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.requested_amount }}</td>
                  <td>
                    <v-chip small dark class="ml-2" :color="getStatusColor(item.approval_status)">
                      {{ item.approval_status }}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-card flat class="text-subtitle-2 green--text mt-3 ml-6" max-height="400">
          *Thank you for choosing to Bank with CHUNA Sacco</v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sucessDialog" max-width="290" persistent>
      <v-card>
        <v-card-title class="text-h5"> Success </v-card-title>

        <v-card-text> Success </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" @click="sucessDialog = false">
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" max-width="290" persistent>
      <v-card>
        <v-card-title class="text-h5"> Error </v-card-title>

        <v-card-text> Error </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" @click="errorDialog = false">
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";
import { EventBus } from "@/utils/eventBus";
import payload from "@/mixin/payloadMethods";
import FileMixin from "@/mixin/FileMixin";
export default {
  name: "LoansList",

  mixins: [FileMixin],

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        mti: "0100",
        f11: payload.methods.getMinuteSecond(),
        f68: "Loan List",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: "800700",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };

      v.$store.dispatch("loans/getLoans", data);
    });
  },
  data: function () {
    return {
      headers: [
        {
          text: "Application No",
          align: "start",
          sortable: false,
          value: "application_no",
        },
        // {
        //   text: "Loan No",
        //   align: "start",
        //   sortable: false,
        //   value: "loan_no",
        // },
        { text: "Loan Type", value: "loan_type" },
        { text: "Loan Amount", value: "requested_Amount" },
        { text: "Loan Purpose", value: "loan_purpose" },
        { text: "installement", value: "installement" },
        // { text: "Loan Installment", value: "loan_installment" },
        // { text: "Undergoing Appraisal", value: "appraisal" },
        { text: "Action", value: "actions" },
      ],
      test: "",
      checkbox: false,
      isValid: false,
      dialog: false,
      errorDialog: false,
      sucessDialog: false,
      formData: {
        phone: "",
        amount: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
      paymentOption: "FOSA",
      loanSelected: {},
      runningLoans: [],
      checkGuarantors: false,
    };
  },

  computed: {
    loans() {
      return this.$store.getters["loans/loansGetters"]("loans").reverse();
    },
    clientInfo() {
      return userInfo;
    },
  },
  methods: {
    encodeString(val) {
      const encodedData = btoa(val);
      return encodedData;
    },
    repayLoan(val, option) {
      this.loanSelected = val;
      this.dialog = true;
      this.formData.phone = userInfo.phone_number;
      this.paymentOption = option;
    },
    choosePaymentOption(val) {
      this.paymentOption = val;
    },

    closeDialog() {
      this.loanSelected = {};
      this.dialog = false;
      this.$refs.repaymentForm.reset();
    },

    PayLoan() {
      if (!this.isValid) {
        this.$refs.repaymentForm.validate();
      } else {
        const data = {
          mti: "0200",
          f2: this.formData.phone,
          f3: "640000",
          f4: this.formData.amount,
          f7: payload.methods.getTransactionDate(),
          f11: payload.methods.getMinuteSecond(),
          f12: payload.methods.getHourMinuteSecond(),
          f13: payload.methods.getHourMinute(),
          f37: payload.methods.createRefno(),
          f60: "KES",
          f68: "Loan Repayment Request",
          f90: userInfo.customer_id,
          f91: "LN15038",
          f92: userInfo.member_number,
          f123: "WEB",
          f65: "FOSA",
          f101: userInfo.account_number,
        };

        this.$store.dispatch("loans/payLoan", data);
      }
    },

    redirectToLoanStatements(val) {
      this.$router.push({
        name: "loanStatements",
        params: {
          code: btoa(val), // Assuming 'val' is the correct object
        },
      });
    },
  },

  mounted() {
    EventBus.$on("paySuccess", (val) => {
      if (val === "Successful") {
        this.$toast.success(val);
        this.$refs.repaymentForm.reset();
        this.sucessDialog = true;
      } else {
        this.$toast.error(val);
        this.errorDialog = true;
        this.$refs.repaymentForm.reset();
      }
    });
  },

  watch: {
    checkbox: {
      handler: function () {
        if (this.checkbox) {
          this.formData.amount = this.loanSelected.loan_balance;
        } else {
          this.formData.amount = "";
        }
      },
    },
    dialog: {
      handler: function () {
        if (this.dialog) {
          if (this.loanSelected.guarantors.length > 0) {
            this.checkGuarantors = true;
          } else {
            this.checkGuarantors = false;
          }
        }
      },
    },
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
