var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"height":"100%","background-image":"linear-gradient(to bottom, #dcdcdc, #bbedd9)"},attrs:{"fluid":_vm.$vuetify.breakpoint.mdAndUp ? true : false}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},_vm._l((_vm.test),function(bid,index){return _c('v-col',{key:index,staticClass:"mt-3",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 12 : 6,"md":"3","sm":"12"}},[_c('v-card',{attrs:{"flat":"","color":"#EDEADE","height":_vm.$vuetify.breakpoint.mdAndUp ? 120 : 100}},[_c('v-row',[_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp
                ? 'mt-0 ml-2 black--text'
                : 'text-caption font-weight-bold mt-0 ml-2 black--text'},[_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'text-overline font-weight-bold mt-6 ml-2'
                  : 'text-caption font-weight-bold mt-0 ml-2'},[_vm._v(" "+_vm._s(bid.share_type || "N/A"))]),_c('p'),_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'text-overline font-weight-bold mt-6 ml-2'
                  : 'text-caption font-weight-bold mt-0 ml-2'},[_vm._v(" "+_vm._s(bid.share_balance || ""))]),_c('v-card-actions',{},[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary white--text"},on:{"click":function($event){return _vm.placeBid()}}},[_c('span',[_vm._v("Bid")])])],1)],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }