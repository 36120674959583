import axios from "axios";
import { AuthService } from "@/modules/auth";
import { EventBus } from "@/utils/eventBus";

/**
 * Axios basic configuration
 */
const config = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
};

/**
 * Creating the instance of Axios
 * It is because, in large scale application we may need
 * to consume APIs from more than single server,
 */
const client = axios.create(config);

/**
 * Auth interceptors
 * @description Add auth tokens to every outgoing requests.
 * @param {*} config
 */

const authInterceptor = (config) => {
  if (AuthService.check()) {
    config.headers.common.Accept = "Application/json";
    config.headers.Authorization = `Bearer ${AuthService.token}`;
  }
  config.headers.common.Accept = "Application/json";
  // config.headers["Access-Control-Allow-Origin"] = "*";
  return config;
};

/**
 * Logger interceptors
 * @description Log app requests.
 * @param {*} config
 */
const loggerInterceptor = (config) =>
  /** Add logging here */
  config;

/** Adding the request interceptors */
client.interceptors.request.use(authInterceptor);
client.interceptors.request.use(loggerInterceptor);

/** Adding the response interceptors */
client.interceptors.response.use(
  (response) => {
    if (response.data.f39 == "02") {
      AuthService.logout();
      EventBus.$emit("sessionEnd");
    }
    return Promise.resolve(response);
  },
  (error) => {
    // Logout if unauthenticated
    if (error === 401) AuthService.logout();
    throw error;
  }
);

export default client;
