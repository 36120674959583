<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
  >
    <v-card class="mx-auto mt-3" flat>
      <v-card-title>
        <v-btn color="primary" text :to="{ name: 'balances' }">
          <v-icon>mdi-arrow-left</v-icon>
          <span>Back</span>
        </v-btn>
      </v-card-title>
      <v-card-title>
        <span class="title-font-style">Source Accounts</span>
      </v-card-title>

      <!-- <v-card class="mt-8"> -->
      <v-data-table
        :headers="headers"
        :items="sourceAccs.length > 0 || Array.isArray(sourceAccs) ? sourceAccs : []"
        :items-per-page="11"
        class="elevation-0"
        :mobile-breakpoint="0"
      >
      </v-data-table>
      <!-- </v-card> -->
    </v-card>
  </v-container>
</template>

<script>
// import { EventBus } from "@/utils/eventBus";
import userInfo from "@/modules/auth/clientInfo";
import payload from "@/mixin/payloadMethods";
export default {
  name: "Sources",

  beforeRouteEnter(to, from, next) {
    next(() => {});
  },
  data: function () {
    return {
      headers: [
        {
          text: "Acc No",
          align: "start",
          sortable: false,
          value: "account_no",
        },
        { text: "Name", value: "account_name" },
        { text: "Acc Status", value: "account_status" },
        { text: "Payroll No", value: "payroll_no" },

        { text: "Balance", value: "balance" },
        { text: "Code", value: "bal_code" },
      ],
    };
  },

  computed: {
    sourceAccs() {
      return this.$store.getters["source/sourceGetters"]("sourceAccs");
    },
  },
  methods: {},
  watch: {},
  mounted() {
    const data = {
      f60: "KES",
      f13: payload.methods.getHourMinute(),
      f12: payload.methods.getHourMinuteSecond(),
      f92: userInfo.member_number,
      mti: "0200",
      f11: payload.methods.getMinuteSecond(),
      f68: "GET SOURCE ACCOUNT FOR WEB",
      f2: userInfo.phone_number,
      f37: payload.methods.createRefno(),
      f123: "WEB",
      f3: "432100",
      f7: payload.methods.getTransactionDate(),
      f90: userInfo.customer_id,
    };

    this.$store.dispatch("source/getSourceAccs", data);
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
