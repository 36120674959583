// Store your secret key securely, for example, in a configuration file or as a constant
// const secretKey = "your-secret-key";

class AuthService {
  constructor() {
    this.token = window.localStorage.getItem("token");
    this.user = window.localStorage.getItem("user");
  }

  login(token) {
    this.token = window.localStorage.setItem("token", token);
    location.reload();
  }
  check() {
    return !!this.token;
  }

  setUser(user) {
    const userObj = JSON.stringify(user);
    const encodedUser = btoa(userObj);
    this.user = window.localStorage.setItem("user", encodedUser);
  }

  logout() {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user");

    window.location.href = "/";
  }
  // timerLogout() {
  //   window.localStorage.removeItem("token");
  //   window.localStorage.removeItem("user");

  //   window.location.href = "/";
  // }
}

export default new AuthService();
