<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndUp ? true : false" style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    ">
    <v-card flat class="mx-auto memberinfo">
      <v-card-title class="">
        <span class="text-overline red--text"> Upload Documents </span>
      </v-card-title>

      <v-card outlined class="mx-6 mt-n3" style="border: 1px solid rgb(61, 65, 61)">
        <v-card-text class="mt-n3">
          <v-row class="mx-3">
            <v-col class="memberinfo-text">
              Loan Amount :
              {{ getSpecificLoan ? getSpecificLoan.requested_Amount : "N/A" }}
            </v-col>
          </v-row>
          <v-divider class="my-3" />
          <v-row class="mx-3">
            <v-col class="memberinfo-text">
              Loan Type:
              {{ getSpecificLoan ? getSpecificLoan.loan_type : "N/A" }}
            </v-col>
          </v-row>
          <v-divider class="my-3" />
          <v-row class="mx-3">
            <v-col class="memberinfo-text">
              Repayment Period :
              {{ getSpecificLoan ? getSpecificLoan.installement : "N/A" }}
            </v-col>
          </v-row>
          <v-divider class="my-3" />
          <v-row class="mx-3">
            <v-col class="memberinfo-text">
              Loan Purpose :
              {{ getSpecificLoan ? getSpecificLoan.loan_purpose : "N/A" }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card-text>
        <v-form v-model="isValid" ref="transferForm" v-on:submit.prevent>
          <v-row>
            <v-col cols="12" md="4">
              <span :class="$vuetify.breakpoint.mdAndUp
                ? 'text-overline new-main'
                : 'mob-main-font-style'
                ">
                Select document below
              </span>

              <v-autocomplete :items="documents" v-model="formData.selectedDoc" outlined dense :rules="rules.required"
                :item-value="(item) => item" :item-text="(item) => item" label="" class="small-label"></v-autocomplete>
            </v-col>
            <v-col class="mt-8">
              <div>
                <!-- Button to trigger the file input -->
                <v-btn color="#25ad64" @click="openFileInput">
                  <v-icon>mdi-upload</v-icon>Select File
                </v-btn>

                <!-- Custom file input -->
                <input type="file" accept="application/pdf" style="display: none" ref="fileInput"
                  @change="handleFileChange" />
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text v-if="uploadedDocs.length !== 0">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Document Type</th>
                <th class="text-left">Document Name</th>
                <th class="text-left">Preview</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in uploadedDocs ? uploadedDocs : []" :key="i">
                <td>{{ item.doc }}</td>
                <td>{{ item.docFile }}</td>
                <td>
                  <v-btn v-if="item.url" color="teal" small class="white--text"
                    @click="previewImage(item.url)">Preview</v-btn>


                  <v-btn color="red" small class="white--text ml-2" @click="deleteFile(i)">
                    Delete File
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-divider class="mt-n4" />
      <v-card-actions class="mt-0">
        <v-spacer />
        <v-btn v-if="$vuetify.breakpoint.mdAndUp" depressed class="white--text" color="#ff4242"
          :to="{ name: 'loansList' }">
          <span><v-icon small class="">mdi-arrow-left</v-icon> Back</span>
        </v-btn>
        <v-btn v-if="$vuetify.breakpoint.mdAndUp" depressed class="white--text" color="#25ad64" @click="saveAllImages">
          <span><v-icon small class="mr-2">mdi-content-save</v-icon>save and
            continue</span>
        </v-btn>
        <v-btn v-if="!$vuetify.breakpoint.mdAndUp" depressed class="white--text" color="#ff4242"
          :to="{ name: 'loansList' }">
          <span><v-icon small class="">mdi-arrow-left</v-icon> Back</span>
        </v-btn>
        <v-btn v-if="!$vuetify.breakpoint.mdAndUp" small depressed class="white--text" color="#25ad64"
          @click="saveAllImages">
          <span><v-icon small class="mr-2">mdi-content-save</v-icon> save and
            continue</span>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="previewDialog" persistent max-width="1100">
      <v-card>
        <v-card-title class="text-h5"> Preview </v-card-title>

        <v-card-text>

          <embed :src="displayImage" type="application/pdf" width="1000" height="600px" alt="Selected PDF" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" @click="previewDialog = false">
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";
import payload from "@/mixin/payloadMethods";
import { EventBus } from "@/utils/eventBus";

export default {
  name: "uploadDocuments",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        mti: "0100",
        f11: payload.methods.getMinuteSecond(),
        f68: "Loan List",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: "800700",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };

      v.$store.dispatch("loans/getLoans", data);
    });
  },
  data() {
    return {
      documents: ["ID FRONT", "ID BACK", "PAYSLIP", "other"],
      selectedFile: null,
      isValid: false,
      previewDialog: false,
      displayImage: "",
      uploadedDocs: [],
      newArray: [],
      formData: {
        selectedDoc: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
    };
  },
  computed: {
    loans() {
      return this.$store.getters["loans/loansGetters"]("loans").reverse();
    },
    getSpecificLoan() {
      const applicationId = this.$route.params.code;
      return this.loans.find(
        (loan) => loan.application_no === parseInt(applicationId)
      );
    },
  },
  methods: {

    deleteFile(index) {
      this.uploadedDocs.splice(index, 1); // Remove the file at the given index from the array
     
    },

    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];

      if (file) {
        this.selectedFile = file;
        this.uploadPDF(); // Automatically upload the file when selected
      }
    },
    uploadPDF() {
      if (!this.selectedFile) {
        alert("Please select a PDF file first.");
        return;
      }

      const formData = new FormData();
      formData.append("pdf_file", this.selectedFile);

      fetch(
        "https://netbanking.chunasacco.co.ke/chuna_ussd/chuna_sacco_assets/upload.php",
        {
          method: "POST",
          body: formData,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status_code === 1000) {
            const obj = {
              doc: this.formData.selectedDoc,
              docFile: this.selectedFile.name,
              url: data.file_url,
            };

            this.newArray.push(obj);
            this.uploadedDocs = [...this.newArray];
            console.log(this.uploadedDocs);
          }
          alert("File uploaded successfully.");
          this.formData.selectedDoc = null
        })
        .catch((error) => {
          alert("An error occurred during the upload.");
          console.error(error);
        });
    },
    previewImage(val) {
      const baseURL = "https://netbanking.chunasacco.co.ke/";
      this.displayImage = baseURL + val;
      this.previewDialog = true;

    },
    saveAllImages() {
      this.uploadedDocs.forEach((doc) => {
        const data = {
          f60: "KES",
          f7: payload.methods.getTransactionDate(),
          f11: payload.methods.getMinuteSecond(),
          f12: payload.methods.getHourMinuteSecond(),
          f13: payload.methods.getHourMinute(),
          f37: payload.methods.createRefno(),
          f92: userInfo.member_number,
          mti: "0100",
          f61: doc.url,
          f62: this.$route.params.code, 
          f63: doc.doc ,
          f64: userInfo.phone_number,
          f68: "Upload Document Online Loans",
          f2: userInfo.phone_number,
          f123: "WEB",
          f3: "800900",
          f90: userInfo.customer_id,
        };

        this.$store.dispatch("loans/uploadDoc", data);
        EventBus.$emit("snackbar", {
          color: "#25ad64",
          text: "Document uploaded successfully",
          link: null,
          timeout: 10000,
        });
        this.$router.push({ name: 'loansList' });
      });
    },
  },
};
</script>

<style scoped>
.text-overline {
  font-size: 16px !important;
}

.memberinfo-text {
  font-size: 14px;
}

.mt-n3 {
  margin-top: -1rem;
}

.mx-6 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.small-label {
  font-size: 14px;
}

.new-main {
  font-size: 16px !important;
}

.mob-main-font-style {
  font-size: 14px !important;
}

.mt-n4 {
  margin-top: -1.5rem;
}
</style>
