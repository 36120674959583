<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndUp ? true : false" style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    " class="">
    <!-- <v-card elevation="2" class="mt-6"> -->

    <v-row class="mt-4">
      <v-card class="mx-auto" width="800" flat>
        <v-card-title>
          <span class="title-font-style">Deposit to Account</span>
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-form v-model="isValid" ref="transferForm" v-on:submit.prevent>
            <span :class="$vuetify.breakpoint.mdAndUp
              ? 'text-overline new-main'
              : 'mob-main-font-style'
              ">
              Deposit to(TO)</span>
            <v-autocomplete :items="newArray" v-model="formData.to" outlined dense :rules="rules.required"
              :item-value="(item) => item" :item-text="(item) => item.account_name"
              label="search your account"></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-divider class="mt-n4" />

        <v-card-text class="text-center mt-n2">
          <v-card-text class="mt-n6">
            <v-row align="start" justify="start">
              <v-col cols="12"> </v-col>
              <v-btn-toggle v-model="toggle_exclusive" rounded>
                <v-btn small :class="paymentOption === 'mpesa' ? 'white--text' : ''"
                  :color="paymentOption === 'mpesa' ? 'success' : ''" @click="choosePaymentOption('mpesa')">
                  <v-icon small class="white--text" left v-if="paymentOption === 'mpesa'">
                    mdi-check
                  </v-icon>
                  M-PESA
                </v-btn>
                <v-btn disabled small :class="paymentOption === 'airtel' ? 'white--text' : ''"
                  :color="paymentOption === 'airtel' ? 'success' : ''" @click="choosePaymentOption('airtel')">
                  <v-icon small class="white--text" left v-if="paymentOption === 'airtel'">
                    mdi-check
                  </v-icon>
                  AIRTEL
                </v-btn>
                <v-btn disabled small :class="paymentOption === 'telkom' ? 'white--text' : ''"
                  :color="paymentOption === 'telkom' ? 'success' : ''" @click="choosePaymentOption('telkom')">
                  <v-icon small class="white--text" left v-if="paymentOption === 'telkom'">
                    mdi-check
                  </v-icon>
                  TELKOM
                </v-btn>
              </v-btn-toggle>
            </v-row>
          </v-card-text>
        </v-card-text>

        <v-card-text class="text-center">
          <v-card-text>
            <v-row align="start" justify="start">
              <v-col cols="12" class="mt-n3">
                <v-radio-group :class="$vuetify.breakpoint.mdAndUp ? 'mt-4' : 'mt-n6'" v-model="radioButton" row>
                  <v-radio small label="SELF" value="SELF"></v-radio>
                  <!-- <v-radio small label="OTHER" value="OTHER"></v-radio> -->
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card-text>

        <v-card-text :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-n6'">
          <v-form v-model="isTransfer" ref="repaymentForm" v-on:submit.prevent>
            <v-text-field dense outlined label="M-Pesa Number" placeholder="Enter Mpesa number"
              v-model="payformData.phone" :rules="rules.required" class="small-label" />
            <v-text-field dense outlined label="Amount to Pay" placeholder="Enter Amount to Pay"
              v-model="payformData.amount" :rules="rules.numberValidationRule" class="small-label" />
          </v-form>
        </v-card-text>
        <v-card-actions class="">
          <v-spacer />

          <v-btn v-if="$vuetify.breakpoint.mdAndUp" class="white--text" color="#25ad64" @click="checkCost()">
            <span>Submit</span>
          </v-btn>
          <v-btn v-if="!$vuetify.breakpoint.mdAndUp" small @click="checkCost()" class="white--text" color="#25ad64">
            <span>Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <div class="text-center">
      <v-dialog persistent v-model="dialog" width="400" height="600">
        <v-card>
          <v-card-text class="justify-center service-title-text">
            Transcation Cost
          </v-card-text>
          <v-divider />

          <!-- Add transacionCost from Api -->
          <v-card-text class="justify-center service-text">
            You will be Charged KES {{ transacionCost.charges }} as transacion
            Cost
          </v-card-text>

          <v-card-actions>
            <v-btn small text class="white--text" color="red" :to="{ name: 'mainDashboard' }">
              Back
            </v-btn>
            <v-spacer></v-spacer>

            <v-spacer />
            <v-btn small class="white--text" color="success" @click="Proceed()">
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  </v-container>
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";

import payload from "@/mixin/payloadMethods";
import { EventBus } from "@/utils/eventBus";
export default {
  name: "deposit",
  components: {},

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        mti: "0200",
        f11: payload.methods.getMinuteSecond(),
        f68: "GET SOURCE ACCOUNT FOR WEB",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: "431000",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };
      v.$store.dispatch("source/getSourceAccs", data);

      const setdata = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        mti: "0100",
        f11: payload.methods.getMinuteSecond(),
        f68: "GET DESTINATION ACCOUNT FOR WEB",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: "432000",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };
      v.$store.dispatch("destination/getDestinationAccounts", setdata);
    });
  },
  data() {
    return {
      newArray: [],
      sucessDialog: false,
      errorDialog: false,
      isValid: false,
      dialog: false,
      paymentOption: "mpesa",
      radioButton: "",
      isTransfer: false,
      toggle_exclusive: undefined,
      errorText: "",

      destinations: [],
      formData: {
        to: "",
        from: "",
        amount: "",
      },
      payformData: {
        phone: "",
        amount: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],

        numberValidationRule: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
          (value) =>
            (value && parseInt(value) >= 1) || "Value must not be below 1",
          (value) =>
            (value && parseInt(value) >= 50) || "Value must not be below 50",
        ],
      },
    };
  },

  computed: {


    fosa() {
      return this.$store.getters["destination/destinationGetters"](
        "destinations"
      ).fosa_accounts;
    },

    sourceAccs() {
      return this.$store.getters["source/sourceGetters"]("sourceAccs");
    },
    transacionCost() {
      return this.$store.getters["Profile/profileGetters"]("cost");
    },
  },
  methods: {
    choosePaymentOption(val) {
      this.paymentOption = val;
    },

    checkCost() {
      if (!this.isValid) {
        this.$refs.transferForm.validate();
      } else {
        if (!this.isTransfer) {
          this.$refs.repaymentForm.validate();
        } else {
          if (this.payformData.amount === "0") {
            this.$toast.error("Inavlid Amount");
          } else {
            this.sendMoney();
          }
        }
      }
    },
    sendMoney() {
      const newPhone = this.payformData.phone.replace(/^0|254/g, "");
      const NP = "254" + newPhone;

      const balCode = `${this.fosa[0].bal_code}${userInfo.member_number}`;
      const data = {
        mti: "0200",
        f0: "1",
        f2: userInfo.phone_number,
        f3: "470000",
        f4: this.payformData.amount,
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f60: "KES",
        f65: balCode,
        f68: "Customer Deposit Using STK",
        f90: userInfo.customer_id,
        f103: NP,
        f123: "WEB",
        f92: userInfo.member_number,
        f93: userInfo.account_number,
        f101: false,
      };

      this.$store.dispatch("destination/depositMoney", data);
    },
    Proceed() {
      this.dialog = false;
      this.sendMoney();
      this.$refs.transferForm.reset()
    },
  },
  watch: {
    fosa: {
      handler: function () {
        if (this.fosa.length > 0) {
          this.newArray = [...this.fosa];
          this.formData.to = this.fosa[0];
        }
      },
    },
    radioButton: {
      handler: function () {
        if (this.radioButton === "SELF") {
          this.payformData.phone = userInfo.phone_number;
        } else {
          if (this.radioButton === "OTHER") {
            this.payformData.phone = "";
          }
        }
      },
    },
  },
  mounted() {
    this.radioButton = "SELF";
    EventBus.$on("successSendMoney", () => {
      this.radioButton = "";
      this.$refs.repaymentForm.reset();
      setTimeout(() => {
        this.$router.push({ name: "mainDashboard" });
      }, 10000);



    });
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
