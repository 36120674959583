import routes from "./statementRoutes";
import store from "./statementStore";

export default {
  install(Vue, options) {
    options.router.addRoute("/", ...routes);
    options.store.registerModule("Statement", store);
  },
};

