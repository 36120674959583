import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import auth from "./modules/auth";
import appAlert from "./plugins/appAlert";
import confirmation from "./plugins/confirmtion";
import loader from "./plugins/loader";
import router from "./router";
import { filters } from "./utils";
import dashboard from "./modules/dashboard";
import statement from "./modules/statement";
import profile from "./modules/profile";
import loans from "./modules/loans";
import destination from "./modules/destination";
import source from "./modules/source";
import loyalty from "./modules/loyalty";
import market from "./modules/market";
import transfer from "./modules/transfer";
import Toast from 'vue-toastification';
import { setupTimers } from "./utils/auto-logout";
import 'vue-toastification/dist/index.css'
import { EventBus } from "@/utils/eventBus";
import Swal from 'sweetalert2';
import "./custom-styles.css";

// import VueSilentbox from 'vue-silentbox';



Vue.config.productionTip = false;
// Vue.config.devtools = true;

const options = {
  store,
  router,
 
};

EventBus.$on("showToast", ({ type, message, icon }) => {
  // Vue.prototype.$toast[type](message);

  Swal.fire({
    title: type,
    text: message,
    icon: icon,
    customClass: {
      icon: 'custom-icon-class'
    }
  });
});




Vue.use(appAlert);
Vue.use(confirmation);
Vue.use(loader);
Vue.use(auth, options);
Vue.use(dashboard, options);
Vue.use(profile, options);
Vue.use(destination, options);
Vue.use(source, options);
Vue.use(loans, options);
Vue.use(loyalty, options);
Vue.use(market, options);
Vue.use(transfer, options);
Vue.use(statement, options);
Vue.use(Toast);
Vue.use(setupTimers);

// Vue.use(VueSilentbox);


/* Register Filters */
filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
